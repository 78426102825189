import Form from '../../components/form/form'
import './feedback-round.scss'
import useWindowDimensions from "../../../../shared/hooks/useWindowDimensions";

const FeedBackRound = () => {
    const {isMobile} = useWindowDimensions();
    return (
        <div className='feedBackRound__wrapper'>
            <div className='feedBackRound__content'>
                <h2 className='feedBackRound__header'>
                    <span>Оставьте заявку</span>
                    <p>и наш менеджер скоро свяжется с вами</p>
                </h2>
                {/* <Form isPhone={false} modifier='form__right-position__roundForm' /> */}
                <Form isPhone={false} modifier='form-round' textButton={isMobile ? 'Записаться' : 'Оставить заявку'}/>
            </div>
        </div>
    )
}

export default FeedBackRound
