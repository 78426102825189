import AttributeItem from "../../singel-card/components/attribute-item";

const Attributes = ({getFieldValue}: any) => {
    return <div className="single__attributes">
        <AttributeItem name={'Комнатность'}
                       value={getFieldValue('source_layout_temp__room_number')}/>
        <AttributeItem name={'Площадь'}
                       value={`${getFieldValue('source_layout_temp__total_area')} м²`}/>
        <AttributeItem name={'Дом'} value={getFieldValue('object_id', true)}/>
        <AttributeItem name={'Этаж'} value={getFieldValue('source_layout_temp__floor')}/>
    </div>
}

export default Attributes