import Skeleton from "../../../singel-card/components/skeleton";
import React from "react";

const PriceInfoItem = ({loading, value, title}: any) => {
    return !loading ? value && <div className="detail-apartment__item">
        <div className="detail-apartment__name">{title}</div>
        <p className="detail-apartment__desc">{value}</p>
    </div> : <Skeleton height={'1.2em'}/>
}

export default PriceInfoItem