import './style.scss';
import React from "react";


const FilterItem = ({name, type, isActive, onClick}: any) => {

    return (
        <button
            className={`filters__item ${type !== 'main' ? 'room': ''} ${
                isActive ? 'active' : ''
            }`}
            onClick={onClick}
        >
            {name}
        </button>
    );
};
export default FilterItem