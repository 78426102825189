import React, {forwardRef, useRef, useState} from "react";
import InputMask from "react-input-mask";

const ProcessPopap = forwardRef((_, ref: any) => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        dataConsent: false,
    });
    const [error, setError] = useState("");
    const successRef = useRef<HTMLDivElement | null>(null);
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const [phone, setPhone] = useState('')
    const validateForm = () => {
        let isValid = true;

        if (!formData.dataConsent) {
            setError("Не все поля заполнены");
            isValid = false;
        } else if (!validateNameAndPhone()) {
            setError("Введите корректные имя и номер телефона");
            isValid = false;
        } else {
            setError("");
        }

        return isValid;
    };

    const validateNameAndPhone = () => {
        const nameValid = formData.name.trim().length > 0;
        const phoneValid = /^\+?[0-9\s-]+$/.test(formData.phone.trim());
        return nameValid && phoneValid;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, type, checked} = e.target;
        setFormData((prev: any) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            showSuccess();
        }
    };

    const showSuccess = () => {
        const overlay = overlayRef.current;
        const success = successRef.current;

        if (overlay && success) {
            overlay.style.display = "block";
            success.style.display = "block";

            overlay.addEventListener("click", (event: any) => {
                if (!success.contains(event.target as Node)) {
                    hideSuccess();
                }
            });
        }
    };

    const hideSuccess = () => {
        const overlay = overlayRef.current;
        const success = successRef.current;

        if (overlay && success) {
            overlay.style.display = "none";
            success.style.display = "none";
        }
    };
    return <div className="process-popap active" ref={ref}>
        <div className="process-popap__header">
            <h3>Оставьте заявку</h3>
            <p>и мы подберем для вас лучший объект</p>
        </div>
        <form className="process__form" onSubmit={handleSubmit}>
            <div className="process__form__inputs">
                <div className="process__form__input">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Ваше имя"
                    />
                </div>
                <div className="process__form__input">
                    <InputMask
                        mask="+7 999 999 99 99"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                            handleChange(e);
                        }}
                        className="phone-input"
                        placeholder="Ваш номер телефона"
                    >
                        {(inputProps: any) => <input type="tel" name={'phone'} {...inputProps}
                                                     className="form__phone"/>}
                    </InputMask>

                </div>
            </div>
            {error && <div className="formError">{error}</div>}
            <button type="submit" className="button">Подобрать объект</button>
            <p className="process__form__info">Нажимая кнопку «Подобрать объект», вы подтверждаете свое согласие
                на обработку персональных данных</p>
            <label className="process__form-politic">
                <input type="checkbox"
                       className="visually-hidden"
                       name="dataConsent"
                       checked={formData.dataConsent}
                       onChange={handleChange}
                />
                <span>Я даю согласие на обработку своих персональных данных</span>
            </label>
        </form>
        <div className="overlay" ref={overlayRef}></div>
        <div className="success" ref={successRef} style={{display: "none"}}>
            <p>Заявка успешно отправлена!</p>
        </div>
    </div>
})

export default ProcessPopap;