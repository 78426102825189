import React, { useState } from 'react'
import './checkbox.scss'

type CheckboxProps = {
  label?: string; // Метка рядом с чекбоксом (необязательная)
  onChange?: (checked: boolean) => void; // Callback при изменении состояния чекбокса
  checked: boolean; // Текущее состояние чекбокса (обязательное)
};

const Checkbox: React.FC<CheckboxProps> = ({ label, onChange, checked }) => {
  const handleCheckboxChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <label className='custom-checkbox'>
      <input type='checkbox' className='custom-checkbox__input' checked={checked} onChange={handleCheckboxChange} />
      <span className='custom-checkbox__box'>
        {checked && ( // Показываем SVG только если чекбокс активен
          <img src='/img/check-mark.svg' alt='Галка' />
        )}
      </span>
      {label && <span className='custom-checkbox__label'>{label}</span>}
    </label>
  )
}

export default Checkbox
