import {useDetectOutsideClick} from "../../../../shared/hooks/useDetectedOutsideClick";
import {useEffect, useRef} from "react";
import './index.scss'

const DropdownFilter = ({label, children, isActiveProp, onToggle}: any) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);
    const closeDropdown = () => setIsActive(false);

    useEffect(() => {
        if (isActiveProp) {
            onClick();
            onToggle(false)
        }
    }, [isActiveProp]);



    return (
        <div className="filters__dropdown" ref={dropdownRef}>
            <button className={`filters__drop-item ${isActive ? 'active' : ''}`} onClick={onClick} dangerouslySetInnerHTML={{__html:label }} />
            {isActive && <ul className={`filters__list ${isActive ? 'active' : ''}`}>
                {children(closeDropdown)}
            </ul>}
        </div>
    );
};

export default DropdownFilter