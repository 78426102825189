import React from 'react'
import './slider-button.scss'

const SliderButton = ({ arrowDirection, className }: any) => {
  const pathArrow = arrowDirection == 'left' ? '/img/arrow-left.svg' : '/img/arrow-right.svg'
  return (
    <div className='slider-button'>
      <button className={className}>
        <img src={pathArrow} alt='стрелка' />{' '}
      </button>
    </div>
  )
}

export default SliderButton
