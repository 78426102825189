import {useEffect, useRef, useState} from "react";

const ReviewItem = ({review}: any) => {
    const reviewContentRef = useRef<HTMLParagraphElement | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const maxLines = 7;

    useEffect(() => {
        if (reviewContentRef.current) {
            const lineHeight = parseFloat(getComputedStyle(reviewContentRef.current).lineHeight);
            const maxHeight = lineHeight * maxLines;

            if (reviewContentRef.current.scrollHeight > maxHeight) {
                reviewContentRef.current.style.maxHeight = `${maxHeight}px`;
                setIsOverflowing(true);
            }
        }

    }, []);

    const toggleExpand = (e: any) => {
        e.preventDefault();
        if (reviewContentRef.current) {
            const lineHeight = parseFloat(getComputedStyle(reviewContentRef.current).lineHeight);
            if (isExpanded) {
                reviewContentRef.current.style.maxHeight = `${lineHeight * maxLines}px`;
            } else {
                reviewContentRef.current.style.maxHeight = `${reviewContentRef.current.scrollHeight}px`;
            }
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <li className="review">
            <div className="review__header">
                <div className="review__person-img">{review.letter}</div>
                <div className="review__person-info">
                    <p>{review.name}</p>
                </div>
            </div>
            <p ref={reviewContentRef} className={`review__content ${isExpanded ? "expanded" : ""}`}>{review.content}</p>
            {isOverflowing && (
                <a className="review__full" href={review.link} onClick={toggleExpand}>
                    {isExpanded ? 'Скрыть полный отзыв' : 'Читать полный отзыв'} <span>{isExpanded ? '←' : '→'}</span>
                </a>
            )}
        </li>
    );
};

export default ReviewItem;