import './style.css';
import React, {useState} from "react";
import useModal from "../../../../shared/hooks/useModal";
import Form from "../../../../entity/main-page/components/form";

const Footer = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { isOpen, openModal, closeModal } = useModal();
    const toggleHiddenText = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <footer className="footer" id="footer">
            <div className="footer-top">
                <div className="container">
                    <p className="footer-title">Место, где любая идея <br/>становится возможностью</p>
                    <div className="footer-top__marquee">
                        <img className="footer-top__runing-logo" src="/img/runing-logo.svg" alt="логотип"/>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-grid">
                        <div className="footer-logo">
                            <img src="/img/footer-logo.png" alt="логотип"/>
                            <button className="footer-logo-button button" onClick={openModal}>Подобрать квартиру</button>
                            <p>С 2020-{new Date().getFullYear()} Novactiv</p>
                        </div>
                        <div className="footer-menu">
                            <a href="#">Каталог</a>
                            <a href="#">Наши преимущества</a>
                            <a href="#">Фотографии производства</a>
                            <a href="#">Этапы сотрудничества</a>
                            <a href="#">Сертификаты</a>
                            <a href="#">Контакты</a>
                        </div>
                        <div className="footer-social">
                            <div className="footer-flex">
                                <a href="https://vk.com/novactive">
                                    <svg width="21" height="12" viewBox="0 0 21 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.7161 5.77219C12.3321 5.63034 12.3321 5.08859 12.3083 4.71133C12.2123 3.36753 12.6923 1.31598 12.1163 0.443753C11.7085 -0.0753584 9.74017 -0.0278235 8.54057 0.0431016C8.20419 0.0906365 7.79639 0.160808 7.50839 0.302658C7.2204 0.444508 6.9324 0.679919 6.9324 0.89194C6.9324 1.19828 7.65278 1.1515 7.9162 1.55215C8.20419 1.97694 8.20419 2.89595 8.20419 3.65047C8.20419 4.5227 8.05981 5.67863 7.70039 5.72541C7.14821 5.7488 6.8364 5.2063 6.54841 4.80565C5.97241 4.02774 5.39642 3.06044 4.96404 2.11728C4.74824 1.62232 4.62766 1.07982 4.31586 0.867795C3.83586 0.537314 2.97187 0.513924 2.13169 0.537314C1.3637 0.560705 0.259332 0.466389 0.0435272 0.914576C-0.124663 1.40954 0.235525 1.88112 0.427522 2.28253C1.41132 4.38085 2.4673 6.22038 3.76367 7.96483C4.96404 9.59159 6.09145 10.8886 8.29942 11.5722C8.9238 11.7608 11.6594 12.3033 12.2116 11.5722C12.4036 11.2893 12.3559 10.6524 12.4519 10.1809C12.5479 9.70929 12.6678 9.23771 13.1239 9.21432C13.5079 9.19093 13.7237 9.52066 13.9641 9.75683C14.2283 10.0164 14.4441 10.2284 14.6361 10.4646C15.0923 10.9128 15.5723 11.5254 16.1483 11.7616C16.9401 12.0921 18.1643 11.9978 19.3162 11.9502C20.2524 11.9268 20.9244 11.7382 20.9966 11.1957C21.045 10.7709 20.5642 10.1582 20.2762 9.80436C19.5559 8.93213 19.2202 8.67258 18.4039 7.87052C18.0437 7.51665 17.5875 7.13939 17.5875 6.71535C17.5637 6.45579 17.7795 6.22038 17.9715 5.96082C18.8117 4.73472 19.6519 3.8625 20.4198 2.58886C20.6357 2.2116 21.1402 1.33937 20.9482 0.914576C20.7324 0.442999 19.5321 0.584094 18.7879 0.584094C17.8279 0.584094 16.5799 0.513169 16.3395 0.7018C15.8833 1.00814 15.6913 1.50386 15.4755 1.97543C14.9955 3.06044 14.3473 4.16808 13.6754 4.99353C13.435 5.27647 12.9796 5.86575 12.7154 5.77144L12.7161 5.77219Z"
                                            fill="white"/>
                                    </svg>
                                    Вконтакте
                                </a>
                                <a href="https://vk.com/novactive">
                                    <svg width="21" height="12" viewBox="0 0 21 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.7161 5.77219C12.3321 5.63034 12.3321 5.08859 12.3083 4.71133C12.2123 3.36753 12.6923 1.31598 12.1163 0.443753C11.7085 -0.0753584 9.74017 -0.0278235 8.54057 0.0431016C8.20419 0.0906365 7.79639 0.160808 7.50839 0.302658C7.2204 0.444508 6.9324 0.679919 6.9324 0.89194C6.9324 1.19828 7.65278 1.1515 7.9162 1.55215C8.20419 1.97694 8.20419 2.89595 8.20419 3.65047C8.20419 4.5227 8.05981 5.67863 7.70039 5.72541C7.14821 5.7488 6.8364 5.2063 6.54841 4.80565C5.97241 4.02774 5.39642 3.06044 4.96404 2.11728C4.74824 1.62232 4.62766 1.07982 4.31586 0.867795C3.83586 0.537314 2.97187 0.513924 2.13169 0.537314C1.3637 0.560705 0.259332 0.466389 0.0435272 0.914576C-0.124663 1.40954 0.235525 1.88112 0.427522 2.28253C1.41132 4.38085 2.4673 6.22038 3.76367 7.96483C4.96404 9.59159 6.09145 10.8886 8.29942 11.5722C8.9238 11.7608 11.6594 12.3033 12.2116 11.5722C12.4036 11.2893 12.3559 10.6524 12.4519 10.1809C12.5479 9.70929 12.6678 9.23771 13.1239 9.21432C13.5079 9.19093 13.7237 9.52066 13.9641 9.75683C14.2283 10.0164 14.4441 10.2284 14.6361 10.4646C15.0923 10.9128 15.5723 11.5254 16.1483 11.7616C16.9401 12.0921 18.1643 11.9978 19.3162 11.9502C20.2524 11.9268 20.9244 11.7382 20.9966 11.1957C21.045 10.7709 20.5642 10.1582 20.2762 9.80436C19.5559 8.93213 19.2202 8.67258 18.4039 7.87052C18.0437 7.51665 17.5875 7.13939 17.5875 6.71535C17.5637 6.45579 17.7795 6.22038 17.9715 5.96082C18.8117 4.73472 19.6519 3.8625 20.4198 2.58886C20.6357 2.2116 21.1402 1.33937 20.9482 0.914576C20.7324 0.442999 19.5321 0.584094 18.7879 0.584094C17.8279 0.584094 16.5799 0.513169 16.3395 0.7018C15.8833 1.00814 15.6913 1.50386 15.4755 1.97543C14.9955 3.06044 14.3473 4.16808 13.6754 4.99353C13.435 5.27647 12.9796 5.86575 12.7154 5.77144L12.7161 5.77219Z"
                                            fill="white"/>
                                    </svg>
                                    Вк-видео
                                </a>
                            </div>
                            <div className="footer-flex">
                                <a href="https://dzen.ru/id/669e3244f11d201c92c1a62d">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_956_4588)">
                                            <path
                                                d="M21.6334 11.5538C21.6352 11.5538 21.6365 11.5538 21.6382 11.5538C21.761 11.5538 21.878 11.5024 21.9612 11.4122C22.0457 11.3206 22.0875 11.1979 22.0765 11.0738C21.6347 6.21487 17.7878 2.36663 12.9284 1.92399C12.8052 1.91431 12.6812 1.95435 12.5896 2.03927C12.4981 2.12375 12.4471 2.24299 12.4484 2.36751C12.525 8.82495 13.3768 11.4636 21.6334 11.5538ZM21.6334 12.4462C13.3768 12.5364 12.5254 15.175 12.4488 21.6325C12.4475 21.757 12.4986 21.8762 12.5901 21.9607C12.6719 22.036 12.7788 22.0778 12.8888 22.0778C12.902 22.0778 12.9157 22.0773 12.9289 22.076C17.7882 21.6334 21.6352 17.7856 22.0765 12.9262C22.0875 12.8021 22.0461 12.6794 21.9612 12.5878C21.8763 12.4963 21.7579 12.451 21.6334 12.4462ZM11.0422 1.92619C6.1982 2.38159 2.36361 6.22895 1.92405 11.0747C1.91305 11.1988 1.95441 11.3215 2.03933 11.413C2.12249 11.5032 2.23997 11.5543 2.36229 11.5543C2.36405 11.5543 2.36581 11.5543 2.36757 11.5543C10.5969 11.4597 11.4465 8.82231 11.524 2.36927C11.5253 2.24475 11.4738 2.12507 11.3823 2.04059C11.2899 1.95611 11.1662 1.91475 11.0422 1.92619ZM2.36713 12.4457C2.23029 12.4303 2.12293 12.4954 2.03889 12.587C1.95441 12.6785 1.91261 12.8012 1.92361 12.9253C2.36317 17.771 6.1982 21.6184 11.0422 22.0738C11.0558 22.0751 11.0699 22.0756 11.0835 22.0756C11.1935 22.0756 11.3 22.0342 11.3818 21.959C11.4734 21.8745 11.5248 21.7548 11.5235 21.6303C11.4461 15.1777 10.5969 12.5403 2.36713 12.4457Z"
                                                fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_956_4588">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Яндекс Дзен
                                </a>
                                <a
                                    href="https://www.youtube.com/@%D0%9D%D0%9E%D0%92%D0%90%D0%9A%D0%A2%D0%98%D0%92.%D0%90%D0%9D">
                                    <svg width="21" height="14" viewBox="0 0 21 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20.5664 2.19707C20.4497 1.77007 20.2157 1.38014 19.8882 1.06712C19.5608 0.754101 19.1517 0.529204 18.7027 0.415416C15.9807 0.110657 13.2408 -0.0266789 10.5 0.0042693C7.75929 -0.0266789 5.01934 0.110657 2.2973 0.415416C1.84834 0.529204 1.43924 0.754101 1.11181 1.06712C0.784374 1.38014 0.550354 1.77007 0.433639 2.19707C0.135467 3.78427 -0.00950439 5.39402 0.00053573 7.00621C-0.0100031 8.62676 0.134968 10.2448 0.433639 11.8403C0.556404 12.2604 0.793114 12.6425 1.12015 12.9487C1.44717 13.2548 1.85306 13.4741 2.2973 13.5846C5.01934 13.8893 7.75929 14.0267 10.5 13.9957C13.2408 14.0267 15.9807 13.8893 18.7027 13.5846C19.147 13.4741 19.5529 13.2548 19.8798 12.9487C20.2069 12.6425 20.4437 12.2604 20.5664 11.8403C20.8651 10.2448 21.01 8.62676 20.9995 7.00621C21.0096 5.39402 20.8645 3.78427 20.5664 2.19707ZM8.3476 9.98392V4.05344L13.8467 7.00621L8.3476 9.97146V9.98392Z"
                                            fill="white"/>
                                    </svg>
                                    Youtube
                                </a>
                            </div>
                            <div className="footer-flex">
                                <a href="https://www.instagram.com/novactiv_nsk?igsh=MXRtNXBqNm9hOWIxdg==">
                                    Инстаграм
                                </a>
                                <a href="https://rutube.ru/channel/38550570/">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_956_4568)">
                                            <mask id="mask0_956_4568" style={{maskType: "luminance"}}
                                                  maskUnits="userSpaceOnUse"
                                                  x="0" y="0" width="19" height="19">
                                                <path
                                                    d="M14.3939 0H4.60606C2.0622 0 0 2.0622 0 4.60606V14.3939C0 16.9378 2.0622 19 4.60606 19H14.3939C16.9378 19 19 16.9378 19 14.3939V4.60606C19 2.0622 16.9378 0 14.3939 0Z"
                                                    fill="white"/>
                                            </mask>
                                            <g mask="url(#mask0_956_4568)">
                                                <path
                                                    d="M11.7363 9.06624H6.12299V6.84499H11.7363C12.0641 6.84499 12.2921 6.90215 12.4065 7.00195C12.521 7.10175 12.5918 7.28686 12.5918 7.55726V8.35483C12.5918 8.63973 12.521 8.82484 12.4065 8.92465C12.2921 9.02444 12.0641 9.0671 11.7363 9.0671V9.06624ZM12.1214 4.75085H3.74243V14.25H6.12299V11.1595H10.5101L12.5918 14.25H15.2576L12.9624 11.145C13.8086 11.0196 14.1885 10.7603 14.5019 10.333C14.8153 9.90561 14.9724 9.22234 14.9724 8.31047V7.59822C14.9724 7.05739 14.9152 6.63004 14.8153 6.30249C14.7154 5.97492 14.5447 5.69002 14.3021 5.43412C14.046 5.19187 13.7608 5.02125 13.4184 4.90695C13.076 4.80716 12.6482 4.75 12.1214 4.75V4.75085Z"
                                                    fill="white"/>
                                                <path
                                                    d="M28.5 3.8147e-06C28.5 5.24671 24.2467 9.50001 19 9.50001C17.9333 9.50001 16.9077 9.32419 15.9505 8.99997C15.9709 8.77421 15.98 8.54339 15.98 8.31047V7.59822C15.98 6.99344 15.9168 6.46035 15.7791 6.00855C15.6304 5.52134 15.3756 5.10205 15.0335 4.74108L15.0145 4.72104L14.9945 4.70207C14.63 4.35735 14.2168 4.11127 13.7374 3.95122L13.719 3.94508L13.7003 3.93964C13.244 3.80662 12.7172 3.74242 12.1214 3.74242H11.1138V3.74327H10.2659C9.77297 2.59461 9.5 1.32919 9.5 3.8147e-06C9.5 -5.24671 13.7533 -9.5 19 -9.5C24.2467 -9.5 28.5 -5.24671 28.5 3.8147e-06Z"
                                                    fill="white"/>
                                            </g>
                                            <path
                                                d="M14.3945 0.144043H4.60665C2.14229 0.144043 0.144531 2.1418 0.144531 4.60616V14.394C0.144531 16.8584 2.14229 18.8562 4.60665 18.8562H14.3945C16.8589 18.8562 18.8567 16.8584 18.8567 14.394V4.60616C18.8567 2.1418 16.8589 0.144043 14.3945 0.144043Z"
                                                stroke="white" strokeWidth="2"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_956_4568">
                                                <rect width="19" height="19" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Rutube
                                </a>
                            </div>
                            <a href="#" className="footer-social-link">Правила</a>
                            <a href="#" className="footer-social-link">Политика конфиденциальности</a>
                        </div>
                        <div className="footer-info">
                            <a href="tel:+73832208888" className="footer-info-block">
                                <span className="footer-info-title">Позвонить</span>
                                <span>+7 383 220 88 88</span>
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.646447 7.64645L0.292893 8L1 8.70711L1.35355 8.35355L0.646447 7.64645ZM8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                                        fill="white"/>
                                </svg>
                            </a>
                            <a href="mailto:info@novoactiv.ru" className="footer-info-block">
                                <span className="footer-info-title">Написать</span>
                                <span>info@novoactiv.ru</span>
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.646447 7.64645L0.292893 8L1 8.70711L1.35355 8.35355L0.646447 7.64645ZM8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                                        fill="white"/>
                                </svg>
                            </a>
                            <a href="#" className="footer-info-block">
                                <span className="footer-info-title">Адрес</span>
                                <div>
                                    <span>Новосибирск, ул. Советская, 36/1</span>
                                    <span>Новосибирск, ул. Линейная, 27</span>
                                    <span>Новосибирск, ул. Советская, 95</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copy">
                <div className="container">
                    <p className="footer-copy-text">Все права на публикуемые на сайте novactiv.ru материалы принадлежат
                        <span> ооо "Новактив"</span>
                    </p>
                    <p className={`footer-copy-text-hidden ${isExpanded ? "visible" : ""}`}>
                        Пользователь уведомлен, что любые материалы, размещенные на сайте,
                        являются объектами интеллектуальной собственности ооо "Новактив" (правообладателя). Пользователь
                        не
                        вправе без предварительного письменного разрешения правообладателя осуществлять какие-либо
                        действия
                        с объектами интеллектуальной собственности, в противном случае, правообладатель оставляет за
                        собой
                        право на взыскание штрафов, предусмотренных законодательством РФ, а также на обращение в
                        компетентные органы за защитой своих прав и законных интересов.<br/><br/>
                        Любая информация, представленная на данном сайте, носит исключительно информационный характер и
                        ни
                        при каких условиях не является публичной офертой, определяемой положениями статьи
                        <span> 437 ГК РФ.</span>
                    </p>
                    <div
                        className="footer-copy-button"
                        onClick={toggleHiddenText}
                    >
                        {isExpanded ? "Скрыть" : "Подробнее"}
                    </div>
                </div>
            </div>
            {isOpen && <>
                <div className="overlay active" onClick={closeModal}></div>
                <Form modal={true}/>
            </>}
        </footer>
    )
}

export default Footer;