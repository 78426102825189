import React from 'react'
import './input.scss'

const Input = ({ className, name, children, type, value, onChange }: any) => {
  return (
    <>
      <input className={`input ${className}`} name={name} type={type} placeholder={children} value={value} onChange={onChange} />
    </>
  )
}

export default Input
