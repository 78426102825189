import './style.css'
import ReviewsSlider from "../../../../entity/main-page/components/reviews-slider";
import {useRef} from "react";
import Slider from "react-slick";

const reviewsSliderData = [
    {
        letter: "С",
        name: "Светлана Щербакова",
        content: "Благодарны от всей души Венере Бояновой, за комфортное сопровождение от продажи квартиры до окончания сделки, очень приятный и располагающий человек 🌹❤️",
        link: "#"
    },
    {
        letter: "Л",
        name: "Людмила",
        content: "  Наша компания выражает благодарность агентству недвижимости \"Новактив\" за помощь в покупке\n" +
            "                        нового большого офиса и подготовке всех необходимых документов. Настоящие профессионалы своего\n" +
            "                        дела. Специалисты агентства \"Новактив\" всегда на связи, на все вопросы отвечают понятно и\n" +
            "                        развернуто. Сделку по покупке нового офиса провели быстро и без каких либо сложностей! Если\n" +
            "                        выбирать агентство для покупки/продажи недвижимости - то только агентство \"Новактив\"! Однозначно\n" +
            "                        рекомендуем!",
        link: "#"
    },
    {
        letter: "D",
        name: "Denis Glazunov",
        content: "Выражаю благодарность Варыгиной Надежде за огромную проделанную работу (продажа квартиры и\n" +
            "                        покупка дома).\n" +
            "                        Очень приятно, когда рядом с тобой, надёжный человек, способный разобраться во всех возникающих\n" +
            "                        вопросах.\n" +
            "                        Семья очень довольна, уже два месяца наслаждаемся приобретением. Спасибо большое от Дениса, Кати\n" +
            "                        и детей.\n" +
            "                        Хороших сделок.",
        link: "#"
    },
    {
        letter: "E",
        name: "Elena Davydova",
        content: "Огромная благодарность Варыгиной Надежде, очень грамотный специалист. Всю сделку помогала, подсказывала, очень достойно и грамотно объясняла по решению вопросов и задач. Очень рекомендую.",
        link: "#"
    },
    {
        letter: "D",
        name: "Dashka Stukalova",
        content: "Хочу выразить огромнейшую благодарность Варыгиной Надежде за проделанную работу!! Это Профессионал с большой буквы!! Помогла быстро продать квартиру в другом городе и также быстро купить в Новосибирске! Со всей ответственностью подходит к делу, найдет подход к каждому человеку и подберет идеальный вариант! Надежда - очень приятный в общении человек, все объяснит и подскажет! Спасибо огромное за вашу работу!!",
        link: "#"
    },
    {
        letter: "E",
        name: "Elena Tsvetaeva",
        content: "Хочу поделиться своими впечатлениями о сотрудничестве с компанией Новактив! Риелтором была Елена Белоусова, очень сильный специалист в своём деле!!! Подобрала вариант с учётом всех пожеланий☺, всё чётко и максимально понятно. Была с нами на связи 24/7. Компании Новактив хочется пожелать процветания и многомиллионных сделок 😉! Спасибо за таких квалифицированных специалистов!!!",
        link: "#"
    },
    {
        letter: "S",
        name: "Shumelka",
        content: "В период финансовой нестабильности и скачков ипотечных ставок в мою беспокойную голову пришла идея поменять квартиру. Продать, и снова купить. И все максимально быстро. Агент Ульяна Мракова и юрист Ксения Машукова - просто феи, творящие чудеса.",
        link: "#"
    },
    {
        letter: "K",
        name: "Khadia Alimova",
        content: "Хочу выразить благодарность Бояновой Венере! Спасибо большое за оказанные услуги риэлтора. Было все быстро и удобно. Ответственный надёжный риэлтор! Рекомендую всем!",
        link: "#"
    },
    {
        letter: "К",
        name: "Ксения Мяу",
        content: "Сержантов Сергей - это мой ЛУЧШИЙ АГЕНТ из лучшего агентства! Знает, как делать сервис высочайшего уровня! Продал мою квартиру на левом берегу по хорошей цене и в обговоренные сроки! Я очень довольна 😊 Теперь только к нему! Всем рекомендую!",
        link: "#"
    },
    {
        letter: "Т",
        name: "Тимофей Василенко",
        content: "Ольга - профессионал своего дела. Помогла с подбором и организацией аренды, а позже продала квартиру по рыночной цене с материнским капиталом. Огромная благодарность!",
        link: "#"
    },
    {
        letter: "O",
        name: "Olga Zayko",
        content: "Обратились в агентство в конце апреля, работали с Венерой, все сделали очень качественно и быстро. Рекомендую!!!",
        link: "#"
    },
    {
        letter: "О",
        name: "Олежа Бэнцззз",
        content: "СЕРГЕЙ СЕРЖАНТОВ - великолепнейший продавец моего гаража! Нашел покупателя за 38 дней! Сделку провели через Росреестр! Спасибо тебе, Сергей 🙏",
        link: "#"
    },
    {
        letter: "A",
        name: "Artem Samkov",
        content: "Хотелось бы выразить благодарность риелтору Ульяне. Отличный специалист своего дела, вопрос с приобретением недвижимости решился быстро. Всегда на связи, отвечает на все вопросы.",
        link: "#"
    },
    {
        letter: "N",
        name: "Natasha Chesnova",
        content: "Не первый раз покупаю и продаю недвижимость с риэлтором Ульяной Мраковой. Все на высоте! Грамотное сопровождение и помощь в любое время суток.",
        link: "#"
    },
    {
        letter: "V",
        name: "vna108",
        content: "Долго не могла продать квартиру, пока не обратилась к Варыгиной Надежде. Продали и купили новую квартиру за две недели. Завтра празднуем новоселье. Огромное спасибо!",
        link: "#"
    }
];

const Reviews = () => {
    const sliderRef = useRef<Slider | null>(null);
    const nextArrowHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevArrowHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };
    return <section className="reviews" id="reviews">
        <div className="container">
            <div className="reviews__header">

                <div className="reviews__rating">
                    <h2 className="reviews__title title">
                        Отзывы
                        <span>о компании</span>
                    </h2>
                    <a href="https://2gis.ru/novosibirsk/firm/70000001043832567/tab/reviews?m=82.902111%2C55.048003%2F16"
                       target="_blank" className="rating__link">
                        <div className="stars">
                            &#9733;&#9733;&#9733;&#9733;&#9733;
                        </div>
                        <span className="rating__text">4.9 в 2ГИС</span>
                    </a>
                </div>
                <div className="rewiews__buttons">
                    <button
                        className="reviews__button reviews__button-pref"
                        onClick={prevArrowHandler}
                    >
                        &#8592;
                    </button>
                    <button
                        className="reviews__button reviews__button-next"
                        onClick={nextArrowHandler}
                    >
                        &#8594;
                    </button>
                </div>
            </div>
            <ReviewsSlider ref={sliderRef} reviews={reviewsSliderData}/>
        </div>
    </section>
}

export default Reviews