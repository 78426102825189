import React, {useState} from 'react'
import './similar-offers.scss'
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore from 'swiper';
import {Navigation} from 'swiper/modules';

SwiperCore.use([Navigation]);

const SimilarOffer = ({ onSortChange }: any) => {
    const [isListVisible, setListVisible] = useState(false)
    const [activeList, setActiveList] = useState(0);
    const [priceSortOrder, setPriceSortOrder] = useState<number>(0);
    const listItems = ['В этом комплексе', 'В этом доме', 'По цене', 'По площади'];

    const handleItemClick = (index: number) => {
        setActiveList(index);
        if (index === 2) {
            const newSortOrder = priceSortOrder === 1 ? 0 : 1;
            setPriceSortOrder(newSortOrder);
            onSortChange(newSortOrder);
        }
        setListVisible(false);
    };

    const toggleListVisibility = () => setListVisible(!isListVisible);

    return (
        <div className='similar-offer__wrappper'>
            <div className='similar-offer__header'>
                <h2>Похожие предложения</h2>
                <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' onClick={toggleListVisibility}>
                    <path
                        d='M21.25 11.9999H8.895M4.534 11.9999H2.75M4.534 11.9999C4.534 11.4217 4.76368 10.8672 5.17251 10.4584C5.58134 10.0496 6.13583 9.81989 6.714 9.81989C7.29217 9.81989 7.84666 10.0496 8.25549 10.4584C8.66432 10.8672 8.894 11.4217 8.894 11.9999C8.894 12.5781 8.66432 13.1326 8.25549 13.5414C7.84666 13.9502 7.29217 14.1799 6.714 14.1799C6.13583 14.1799 5.58134 13.9502 5.17251 13.5414C4.76368 13.1326 4.534 12.5781 4.534 11.9999ZM21.25 18.6069H15.502M15.502 18.6069C15.502 19.1852 15.2718 19.7403 14.8628 20.1492C14.4539 20.5582 13.8993 20.7879 13.321 20.7879C12.7428 20.7879 12.1883 20.5572 11.7795 20.1484C11.3707 19.7396 11.141 19.1851 11.141 18.6069M15.502 18.6069C15.502 18.0286 15.2718 17.4745 14.8628 17.0655C14.4539 16.6566 13.8993 16.4269 13.321 16.4269C12.7428 16.4269 12.1883 16.6566 11.7795 17.0654C11.3707 17.4742 11.141 18.0287 11.141 18.6069M11.141 18.6069H2.75M21.25 5.39289H18.145M13.784 5.39289H2.75M13.784 5.39289C13.784 4.81472 14.0137 4.26023 14.4225 3.8514C14.8313 3.44257 15.3858 3.21289 15.964 3.21289C16.2503 3.21289 16.5338 3.26928 16.7983 3.37883C17.0627 3.48839 17.3031 3.64897 17.5055 3.8514C17.7079 4.05383 17.8685 4.29415 17.9781 4.55864C18.0876 4.82313 18.144 5.10661 18.144 5.39289C18.144 5.67917 18.0876 5.96265 17.9781 6.22714C17.8685 6.49163 17.7079 6.73195 17.5055 6.93438C17.3031 7.13681 17.0627 7.29739 16.7983 7.40695C16.5338 7.5165 16.2503 7.57289 15.964 7.57289C15.3858 7.57289 14.8313 7.34321 14.4225 6.93438C14.0137 6.52555 13.784 5.97106 13.784 5.39289Z'
                        fill={isListVisible ? '#E0BE74' : '#141414'}
                        stroke={isListVisible ? '#E0BE74' : '#141414'}
                        strokeWidth='1.5'
                        strokeMiterlimit='10'
                        strokeLinecap='round'
                    />
                </svg>
            </div>
            <ul className={`similar-offer__list ${isListVisible ? 'visible' : ''}`}>
                {listItems.map((item, index) => (
                    <li
                        key={index}
                        className={`similar-offer__item ${activeList === index ? 'active' : ''}`}
                        onClick={() => handleItemClick(index)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default SimilarOffer
