import './style.scss'
import {formatPrice} from "../../singel-card/components/card-apartment";
const MarkCard = ({id, image, address, price, title}: any) => {
    return <div className="mapCard">
        <a href={`/apartment/${id}`}>
            <img
                className="mapCard__image"
                src={`https://novactive.homes${image}`}
                alt="фото объекта"
            />
            <div className="mapCard__wrapper">
                <h2 className="mapCard__title">{address}</h2>
                <div className="mapCard__container">
                    <p>{title}</p>
                </div>
                <span className="mapCard__price apartment__meter">Цена: {formatPrice(price)} руб.</span>
            </div>
            <a className="mapCard__link header-button" href="tel:+73832208888">
                Позвонить агенту
            </a>
        </a>
    </div>
};
export default MarkCard