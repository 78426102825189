import Button from "../../../../entity/main-page/components/button";
import {calculatePricePerSquareMeter, formatPrice} from "../../../../entity/singel-card/components/card-apartment";
import Form from "../../../../entity/main-page/components/form";
import React from "react";
import useModal from "../../../../shared/hooks/useModal";
import Skeleton from "../../../../entity/singel-card/components/skeleton";
import PriceInfoItem from "../../../../entity/main-page/components/price-info-item";

const Price = ({price, address, loading}: any) => {
    const {isOpen, closeModal, openModal} = useModal();
    return <>
        <div className="single__detail">
            <div className="single__detail-wrapper">
                <div className="single__price-info">
                    <h3 className="single__price">{!loading ? price && `от ${formatPrice(price)} ₽` :
                        <Skeleton height={'1.2em'}/>}
                    </h3>
                    <div className="single__more-detail detail-apartment">
                        <PriceInfoItem loading={loading} title={'Цена за м2'} value={'от 122 000 ₽'} />
                        <PriceInfoItem loading={loading} title={'Адрес'} value={address} />
                        <PriceInfoItem loading={loading} title={'Метро'} value={'12 мин'} />
                    </div>
                </div>
                <Button title={"Подобрать квартиру"} onClick={openModal} color={"#273E42"}/>
            </div>
        </div>
        {isOpen && <>
        <div className="overlay active" onClick={closeModal}></div>
            <Form modal={true}/>
        </>}</>
}

export default Price