import './style.css'
import Form from "../../../../entity/main-page/components/form";
const ApartmentSelection = () => {
    return <section className="apartment-selection" id="apartment-selection">
        <div className="container">
            <Form title={'Подберем квартиру'} />
        </div>
    </section>
}

export default ApartmentSelection