import './style.css'
import {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import Tabs from "../../../../entity/singel-card/components/tabs";

const partnersList = [
    {
        src: "/img/svg/rascvetai.svg",
        width: 210,
        height: 60,
        alt: "партнер РАСЦВЕТАЙ"
    },
    {
        src: "/img/svg/kedrovii.svg",
        width: 191,
        height: 39,
        alt: "партнер КЕДРОВЫЙ"
    },
    {
        src: "/img/svg/gorLaguna.svg",
        width: 225,
        height: 30,
        alt: "партнер ГОРСКАЯ ЛАГУНА"
    },
    {
        src: "/img/svg/berlin.svg",
        width: 148,
        height: 62,
        alt: "партнер berlin"
    },
    {
        src: "/img/svg/vesna.svg",
        width: 161,
        height: 41,
        alt: "партнер VESNA"
    },
    {
        src: "/img/svg/redFox.svg",
        width: 180,
        height: 57,
        alt: "партнер RED FOX"
    },
    {
        src: "/img/svg/pulsar.svg",
        width: 230,
        height: 27,
        alt: "партнер ПУЛЬСАР"
    },
    {
        src: "/img/svg/balance.svg",
        width: 190,
        height: 46,
        alt: "партнер balance"
    },
    {
        src: "/img/svg/noName2.svg",
        width: 75,
        height: 62,
        alt: "партнер"
    },
    {
        src: "/img/svg/bering.svg",
        width: 87,
        height: 78,
        alt: "партнер БЕРИНГ"
    },
    {
        src: "/img/svg/nobel.svg",
        width: 94,
        height: 94,
        alt: "партнер НОБЕЛЬ"
    },
    {
        src: "/img/svg/PROMENADE.svg",
        width: 207,
        height: 62,
        alt: "партнер PROMENADE"
    },
];

const partnersListActive = [
    {
        src: "/img/svg/sber.svg",
        width: 90,
        height: 82,
        alt: "партнер Сбер"
    },
    {
        src: "/img/svg/alfa.svg",
        width: 177,
        height: 40,
        alt: "партнер Альфа Банк"
    },
    {
        src: "/img/svg/gazprom.svg",
        width: 91,
        height: 82,
        alt: "партнер газпромбанк"
    },
    {
        src: "/img/svg/domRF.svg",
        width: 91,
        height: 91,
        alt: "партнер домРФ"
    },
    {
        src: "/img/svg/lefober.svg",
        width: 71,
        height: 71,
        alt: "партнер Левобережный"
    },
    {
        src: "/img/svg/noName1Bank.svg",
        width: 71,
        height: 71,
        alt: "партнер"
    },
    {
        src: "/img/svg/tkb.svg",
        width: 172,
        height: 80,
        alt: "партнер ткб"
    },
    {
        src: "/img/svg/sovkom.svg",
        width: 180,
        height: 20,
        alt: "партнер СОВКОМБАНК"
    },
    {
        src: "/img/svg/rshb.svg",
        width: 195,
        height: 49,
        alt: "партнер РоссельхозБанк"
    },
    {
        src: "/img/svg/VTB.svg",
        width: 121,
        height: 43,
        alt: "партнер ВТБ"
    },
    {
        src: "/img/svg/noaik.svg",
        width: 120,
        height: 120,
        alt: "партнер НОАИК"
    },
    {
        src: "/img/svg/psb.svg",
        width: 78,
        height: 78,
        alt: "партнер PSB"
    },
]

const tabs = [
    {name: 'ЖК'},
    {name: 'Банки'}
]

const Partners = () => {
    const [activeTab, setActive] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);
    const sliderRef = useRef<Slider | null>(null);

    const nextArrowHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevArrowHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const sliderSettings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 450);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section className="partners" id="partners">
            <div className="container">
                <div className="partners__header">
                    <h2 className="partners__title">
                        Наши <span>Партнеры</span>
                    </h2>
                    <div className="partners__buttons">
                        <Tabs tabs={tabs} setActive={setActive} activeTab={activeTab}/>
                        <div className="partners__buttons-right">
                            <button
                                className="partners__button-right partners__slider-left"
                                onClick={prevArrowHandler}
                            >
                                &#8592;
                            </button>
                            <button
                                className="partners__button-right partners__slider-right"
                                onClick={nextArrowHandler}
                            >
                                &#8594;
                            </button>
                        </div>
                    </div>
                </div>


                {isMobile && (
                    <Slider ref={sliderRef} {...sliderSettings} className="partners__list">
                        {(activeTab === 0 ? partnersList : partnersListActive).map((partner, index) => (
                            <li key={index}>
                                <img
                                    src={partner.src}
                                    width={partner.width}
                                    height={partner.height}
                                    alt={partner.alt}
                                />
                            </li>
                        ))}
                    </Slider>
                )}

                {!isMobile && (
                    <>
                        <ul className={`partners__list ${activeTab === 0 ? 'is-active' : ''}`}>
                            {partnersList.map((partner, index) => (
                                <li key={index}>
                                    <img
                                        src={partner.src}
                                        width={partner.width}
                                        height={partner.height}
                                        alt={partner.alt}
                                    />
                                </li>
                            ))}
                        </ul>
                        <ul className={`partners__list ${activeTab === 1 ? 'is-active' : ''}`}>
                            {partnersListActive.map((partner, index) => (
                                <li key={index}>
                                    <img
                                        src={partner.src}
                                        width={partner.width}
                                        height={partner.height}
                                        alt={partner.alt}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </section>
    );
};

export default Partners;