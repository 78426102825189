import './style.scss'
import InfoItem from "../../../../entity/singel-card/components/info-item";
import {formatPrice} from "../../../../entity/singel-card/components/card-apartment";

const SingleInfo = ({meter, floorFull, floorChecked, year, price, priceMeter}: any) => {
    const floorText = `${floorFull} из ${floorChecked}`;
    const meterText = `${meter} м²`;

    return (meter || floorFull || floorChecked|| year || price || priceMeter) && <div className={'single-wrapper'}>
        {meter && <InfoItem title={meterText} desc={'площадь'}/>}
        {floorFull && <InfoItem title={floorText} desc={'этаж'}/>}
        {year && <InfoItem title={year} desc={'год'}/>}
        {price && <InfoItem title={`${formatPrice(price)} ₽`} desc={''}/>}
        {priceMeter && <InfoItem title={`${formatPrice(priceMeter)} за м² `} desc={''}/>}
    </div>
}
export default SingleInfo