import React, { useState } from 'react'
import Button from '../../ui/buttons/button'
import Input from '../../ui/input/input'
import InputMask from 'react-input-mask'
import Checkbox from '../../ui/checkbox/checkbox'
import Success from '../../../main/components/Popup/Success'
import './form.scss'

const Form = ({ isPhone, modifier, textButton }: { isPhone?: boolean; modifier?: any; textButton?: any }) => {

  const [formData, setFormData] = useState({
    name: "",
    phone_single: "",
    email: "",
    dataConsent: false,
  });

  const [error, setError] = useState("");
  const [successVisible, setSuccessVisible] = useState(false);
  const [phone, setPhone] = useState('');

  const validateNameAndPhone = () => {
    const nameRegex = /^[А-ЯЁа-яё]{2,40}$/;
    const isNameValid = nameRegex.test(formData.name.trim());

    if (!isNameValid) {
      setError("Некорректное имя");
      return false;
    }
    if (isPhone) {
      const phoneRegex = /^(\+7|8)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/;
      const isPhoneValid = phoneRegex.test(formData.phone_single.trim());

      if (!isPhoneValid) {
        setError("Некорректный номер телефона");
        return false;
      }
    }

    return true;
  };

  const validateForm = () => {
    const { dataConsent } = formData;

    if (
      !formData.name ||
      !formData.email ||
      !dataConsent
    ) {
      setError("Не все поля заполнены");
      return false;
    }

    if (isPhone) {
      if (!formData.phone_single) {
        setError("Не все поля заполнены");
        return false;
      }
    }

    if (!validateNameAndPhone()) {
      return false;
    }

    setError("");
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;

    if (type === "checkbox" && name === "conditions") {
      setFormData((prev: any) => ({
        ...prev,
        conditions: checked
          ? [...prev.conditions, value]
          : prev.conditions.filter((condition: any) => condition !== value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setSuccessVisible(true);
      setFormData({
        name: "",
        phone_single: "",
        email: "",
        dataConsent: false,
      })
      setPhone('')
    }
  };

  const [checked, setChecked] = useState(false)

  const handleCheckboxChange = (isChecked: boolean) => setChecked(isChecked);
  return (
    <>
      <form onSubmit={handleSubmit} className={`form_object ${modifier}`} action='#'>
        <Input className='form__field' type='text' name="name" value={formData.name} onChange={handleChange}>
          Имя
        </Input>
        {isPhone ? (
          <div className='form__input-phone form__field'>
            <InputMask
              mask="+7 999 999 99 99"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                handleChange(e);
              }}
              className="phone-input"
              placeholder="Телефон"
            >
              {(inputProps: any) => <input type="tel" name={'phone_single'} {...inputProps}
                className="form__phone" />}
            </InputMask>
          </div>
        ) : null}
        <Input className='form__field' type='email' name="email" value={formData.email} onChange={handleChange}>
          E-mail
        </Input>

        <div className='form__right-position'>
          <Button type='submit' name='button__accent'>
            {textButton}
          </Button>
          <Checkbox
            label="Я даю согласие на обработку своих персональных данных"
            checked={formData.dataConsent}
            onChange={(checked) => {
              setFormData((prev) => ({
                ...prev,
                dataConsent: checked,
              }));
            }}
          />
          {error && <div className="form__error">{error}</div>}
        </div>
      </form>
      {successVisible && <Success onClose={() => setSuccessVisible(false)} />}
    </>
  )
}

export default Form



