const OvalDecor = ({color = 'lightgray'}: any) => {
    return <svg className="about__animate" width="146" height="124" viewBox="0 0 146 124"
                xmlns="http://www.w3.org/2000/svg">

        <path id="motionPath"
              d="M141.232 13.4083C148.716 23.7215 148.091 38.5561 140.828 54.3076C133.572 70.0424 119.733 86.5801 101.015 100.164C82.2964 113.748 62.2828 121.777 45.0737 123.797C27.8464 125.819 13.5491 121.813 6.06473 111.5C-1.41967 101.187 -0.794746 86.3522 6.46881 70.6008C13.7247 54.866 27.5636 38.3282 46.282 24.7442C65.0003 11.1602 85.014 3.13088 102.223 1.11129C119.45 -0.910435 133.748 3.09503 141.232 13.4083Z"
              fill="transparent" stroke={color}></path>
        <g style={{transform: 'translate(-21px, -5px)'}}>
            <g width="49" height="14" viewBox="0 0 49 14" fill="none"
               xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M34.6729 6.9268C34.6729 3.40893 37.7361 0.500091 41.5874 0.500091C45.4388 0.500091 48.502 3.40893 48.502 6.9268C48.502 10.4447 45.4388 13.3535 41.5874 13.3535C37.7361 13.3535 34.6729 10.4447 34.6729 6.9268Z"
                    stroke="#E0BE74"/>
                <path d="M34.0723 7.02344L0.999652 7.02343" stroke="#E0BE74" strokeLinecap="round"/>
                <path d="M13.6855 13.2676L13.6855 0.584868" stroke="#E0BE74" strokeLinecap="round"/>
                <path d="M4.70898 13.2676L4.70899 0.584868" stroke="#E0BE74" strokeLinecap="round"/>
            </g>

            <animateMotion dur="5s" repeatCount="indefinite">
                <mpath href="#motionPath"/>
            </animateMotion>
        </g>
    </svg>
}

export default OvalDecor;