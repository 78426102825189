import React, {useEffect, useRef, useState} from 'react';
import styles from './map.module.scss';
import PropTypes from 'prop-types';
import {Box, Tabs, Tab, CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import {YMaps, Map, Placemark, Clusterer} from '@pbe/react-yandex-maps';
import axios from 'axios';
import {isRoom} from "../../../../entity/singel-card/components/card-apartment";
import useWindowDimensions from "../../../../shared/hooks/useWindowDimensions";
import LogoIcon from "../../../../shared/assets/icons/logo-icon";
import PlusIcon from "../../../../shared/assets/icons/plus-icon";
import MinusIcon from "../../../../shared/assets/icons/minus-icon";
import MarkCard from "../../../../entity/object/mark-card";
import {renderToStaticMarkup} from "react-dom/server";


function CustomTabPanel(props: any) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const CustomTabs = styled(Tabs)({
    justifyContent: 'center',
    '@media (max-width:767px)': {
        padding: '0 23px',
        marginBottom: '5px',
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const CustomTab = styled(Tab)({
    color: '#141414',
    textAlign: 'center',
    fontSize: '21px',
    flex: ' 0 0 auto',
    fontWeight: 400,
    lineHeight: '1.5',
    textTransform: 'capitalize',
    paddingBottom: '15px',
    borderBottom: '1px solid #CBCBCB',

    '@media (max-width:996px)': {
        fontSize: '19px',
    },
    '@media (max-width:768px)': {
        fontSize: '17px',
    },

    '@media (max-width:520px)': {
        paddingBottom: '13px',
        fontSize: '15px',
    },

    '&.Mui-selected': {
        color: '#141414',
        borderBottom: '1px solid #E0BE74',
    },
});


const Maps = ({coordinates = [55.087625, 82.970538], version = true, imagesLayout, areas, room}: any) => {
    const [value, setValue] = useState(1);
    const [isClient, setIsClient] = useState(false);
    const [placemarks, setPlacemarks] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [loading, setLoading] = useState(true);
    const city = coordinates;

    const placemarkIcon = '/img/svg/mark.svg';
    const mapRef = useRef<HTMLButtonElement | any>(null);

    const {width} = useWindowDimensions();

    const handleZoomIn = () => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            const currentZoom = mapInstance.getZoom();
            mapInstance.setZoom(currentZoom + 1, {checkZoomRange: true});
        }
    };

    const handleZoomOut = () => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            const currentZoom = mapInstance.getZoom();
            mapInstance.setZoom(currentZoom - 1, {checkZoomRange: true});
        }
    };
    const getPoints = async () => {
        setLoading(true);
        try {

            const res = await axios.get('https://novactive.homes/api/object/listmap_nmarket/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Credentials": true
                }
            });

            const worker = new Worker(new URL('../../../../shared/helpers/dataWorker.js', import.meta.url));
            worker.onmessage = (e) => {
                setPlacemarks(e.data);
                setLoading(false);
            };

            worker.postMessage(res.data);

        } catch (error) {
            console.error('Ошибка при получении данных меток:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectedTab === 1) {
            setIsClient(true);
            getPoints();
        }
    }, [selectedTab]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setCenter(coordinates, 14);
        }
    }, [coordinates]);

    if (!isClient) {
        return null;
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
        setSelectedTab(newValue);
    };

    const mapState = {
        center: city,
        zoom: 14,
        controls: [],
    };


    const handleClusterClick = (cluster: any, mapInstance: any) => {
        const geoObjects = cluster.getGeoObjects();
        const placemarkInfo = geoObjects.map((placemark: any) => placemark.properties.getAll());

        const clusterCoordinates = cluster.geometry.getCoordinates();

        const content = (
            <>
                {placemarkInfo.map((info: any) => (
                    <MarkCard
                        key={info.id}
                        id={info.id}
                        address={info.address}
                        price={info.price}
                        image={info.image}
                        title={info.title}
                    />
                ))}
            </>
        );

        mapInstance.setCenter(clusterCoordinates, mapInstance.getZoom(), {
            duration: 300,
        });

        mapInstance.balloon.open(clusterCoordinates, {
            contentBody: renderToStaticMarkup(content),
        });


    };

    const handlePlacemarkClick = (placemark: any, mapInstance: any) => {
        const {id, address, price, image, title} = placemark.properties.getAll();
        const placemarkCoordinates = placemark.geometry.getCoordinates();
        const content = (
            <MarkCard
                id={id}
                address={address}
                price={price}
                image={image}
                title={title}
            />
        );

        mapInstance.balloon.open(placemarkCoordinates, {
            contentBody: renderToStaticMarkup(content)
        });
    };

    return (
        <section id="map" className={styles.map}>
            <div className={styles.map__container}>

                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',

                    '& > div:first-of-type': {
                        marginTop: 'auto !important',
                        '@media (max-width: 996px)': {
                            height: '360px !important',
                        },
                        '@media (max-width: 768px)': {
                            height: '320px !important',
                            overflow: 'hidden'
                        },
                        '@media (max-width: 520px)': {
                            height: '268px !important'
                        },
                        '& > ymaps, & > ymaps > ymaps': {
                            '@media (max-width: 996px)': {
                                height: '360px !important',
                            },
                            '@media (max-width: 768px)': {
                                height: '320px !important',
                            },
                            '@media (max-width: 520px)': {
                                height: '268px !important'
                            }
                        }
                    }
                }}>
                    {loading ? (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
                            <CircularProgress/>
                        </Box>
                    ) : value === 1 ? <div style={{position: "relative"}}>

                            {width > 768 && <div className={styles.btnsMapArrow}>
                                <button onClick={handleZoomIn}>
                                    <PlusIcon/>
                                </button>
                                <button onClick={handleZoomOut}>
                                    <MinusIcon/>
                                </button>
                            </div>}

                            <YMaps query={{
                                apikey: '4ace35bf-16a2-4ca4-88d1-39ce94b3e409',
                                lang: 'ru_RU'
                            }}>
                                <Map defaultState={mapState} width="100%" height={version ? "450px" : "300px"}
                                     modules={['control.FullscreenControl']}
                                     options={{
                                         suppressMapOpenBlock: true,
                                     }}
                                     instanceRef={(ref) => (mapRef.current = ref)}
                                >
                                    <Clusterer
                                        options={{
                                            groupByCoordinates: false,
                                            minClusterSize: 2,
                                            clusterDisableClickZoom: true,
                                        }}
                                        clusters={{
                                            preset: "islands#redClusterIcons",
                                        }}
                                        onClick={(e: any) => {
                                            const target = e.get('target');
                                            const mapInstance = e.get('map');
                                            if (target.getGeoObjects) {
                                                handleClusterClick(target, mapInstance);
                                            }
                                        }}
                                    >
                                        {placemarks.map((placemark: any) => {

                                            return placemark.coordinates && (
                                                <Placemark
                                                    key={placemark.id}
                                                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                                    geometry={placemark.coordinates}
                                                    properties={{
                                                        id: placemark.id,
                                                        image: placemark.image,
                                                        address: placemark.address,
                                                        square: placemark.square,
                                                        floor: placemark.floor,
                                                        price: placemark.price,
                                                        title: placemark.title,
                                                    }}
                                                    options={{
                                                        iconLayout: 'default#image',
                                                        iconImageHref: placemarkIcon,
                                                        iconImageSize: [30, 42],
                                                        iconImageOffset: [-15, -5],
                                                    }}
                                                    onClick={(e: any) => {
                                                        const mapInstance = e.get('map');
                                                        handlePlacemarkClick(e.get('target'), mapInstance);
                                                    }}
                                                />
                                            )
                                        })}
                                    </Clusterer>
                                </Map>
                            </YMaps>
                        </div>
                        : <div className={styles.plan} style={{height: version ? "450px" : "300px"}}>
                            <div className={styles.blockRoom}>
                                <LogoIcon/>
                                {room && <p>{isRoom(room) + ', '} {areas && areas} м² </p>}
                            </div>
                            {imagesLayout && <div className={styles.blockImage}>
                                <img src={`https://novactive.homes${imagesLayout}`} alt=""/>
                            </div>}

                        </div>}

                    <CustomTabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        aria-label="basic tabs example"
                    >
                        <CustomTab label={'Планировка'}/>
                        <CustomTab label={'Карта'}/>
                    </CustomTabs>
                </Box>
            </div>
        </section>
    );
};

export default Maps;
