import './style.scss'
import React, {forwardRef, useEffect, useRef, useState} from "react";
import FilterItem from "../../../../entity/singel-card/components/filter-item-select";
import DropdownFilter from "../../../../entity/singel-card/components/dropdown-filter";
import useWindowDimensions from "../../../../shared/hooks/useWindowDimensions";
import {formatToNumbers} from "../Scheme";
import {useParams} from "react-router-dom";
import {getFilterCard} from "../../../../shared/api/endpoints";
import {debounce} from "@mui/material";

const filterOptions = [
    {type: 'main', name: 'Комнаты'},
    {type: 'main', name: 'Студия'},
];

const roomOptions = [
    {type: 'room', name: '1'},
    {type: 'room', name: '2'},
    {type: 'room', name: '3'},
    {type: 'room', name: '4+'},
];

const mobileOptions = [
    {name: 'Комнаты'},
    {name: 'Студия'},
    {name: '1'},
    {name: '2'},
    {name: '3'},
    {name: '4+'},
];

interface QueryParams {
    price_from?: string;
    price_to?: string;
    rooms?: string;
    area_from?: string;
    area_to?: string;
}

const salesmanOptions = ['Застройщик']
const dateOptions = ['3 кв.2026']

const Filters = forwardRef(({setFilteredCards}: any, ref: any) => {
    const [selectedMobile, setSelectedMobile] = useState<'Комнаты' | 'Студия' | '1' | '2' | '3' | '4+' | null>('Комнаты');
    const [selectedMain, setSelectedMain] = useState<'Комнаты' | 'Студия' | null>('Комнаты');
    const [selectedRoom, setSelectedRoom] = useState<'1' | '2' | '3' | '4+' | '' | null>('');
    const {isMobile} = useWindowDimensions()
    const [isDropdownActive, setIsDropdownActive] = useState<Boolean>(false);
    const {id} = useParams();
    const [countLength, setCountLength] = useState(0);

    const [labelFilter, setLabelFilter] = useState({
        priceLabel: 'Цена ₽',
        squareLabel: 'Площадь квартиры м²',
        dateCompletionLabel: 'Срок сдачи',
        salesmanLabel: 'Продавец'
    })
    const [dataFilter, setDataFilter] = useState({
        type: '',
        priceStart: '',
        priceEnd: '',
        squareStart: '',
        squareEnd: '',
        dateCompletion: [] as string[],
        salesman: [] as string[]
    })

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleFocusKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, closeDropdown: () => void) => {
        e.preventDefault();
        const index = inputRefs.current.findIndex(input => input === e.target);
        if (index !== -1 && index + 1 < inputRefs.current.length) {
            inputRefs.current[index + 1]?.focus();
        } else {
            closeDropdown();
        }
    }


    const handlePriceKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, closeDropdown: () => void) => {
        if (e.key === 'Enter') {
            handleFocusKeyDown(e, closeDropdown);
            setLabelFilter((prev: any) => ({
                    ...prev,
                    priceLabel: `
                        Цена <b>${(dataFilter.priceEnd && !dataFilter.priceStart) ? 0 : dataFilter.priceStart && formatToNumbers(+dataFilter.priceStart) || ''}</b> 
                        <b>${dataFilter.priceEnd && formatToNumbers(+dataFilter.priceEnd) ? 'до ' : ''}</b>
                        <b>${dataFilter.priceEnd && formatToNumbers(+dataFilter.priceEnd) || ''}</b>`
                }
            ));
            setDataFilter((prev: any) => ({
                ...prev,
                priceStart: dataFilter.priceStart || null,
                priceEnd: dataFilter.priceEnd || null,
            }));
        }
    };

    const handleSquareKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, closeDropdown: () => void) => {
        if (e.key === 'Enter') {
            handleFocusKeyDown(e, closeDropdown);
            setLabelFilter((prev: any) => ({
                    ...prev,
                    squareLabel: `Площадь квартиры м²
                                  <b>${(dataFilter.squareEnd && !dataFilter.squareStart) ? 0 : dataFilter.squareStart && dataFilter.squareStart + ' ' || ''}</b> 
                                  <b> ${dataFilter.squareEnd && dataFilter.squareEnd ? 'до ' : ''} </b>
                                  <b>${dataFilter.squareEnd && dataFilter.squareEnd || ''}</b>`
                }
            ));
            setDataFilter((prev: any) => ({
                ...prev,
                squareStart: dataFilter.squareStart || null,
                squareEnd: dataFilter.squareEnd || null,
            }));
        }
    };

    const handleDateCompletionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDataFilter((prev: any) => {
            const updated = dataFilter.dateCompletion.includes(value) ? dataFilter.dateCompletion.filter((item: string) => item !== value) : [...prev.dateCompletion, value];
            return {
                ...prev,
                dateCompletion: updated
            }
        })
    };

    const handleSalesmanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDataFilter((prev: any) => {
            const updated = dataFilter.salesman.includes(value) ? dataFilter.salesman.filter((item: string) => item !== value) : [...prev.salesman, value];

            return {
                ...prev,
                salesman: updated
            }
        })
    };


    useEffect(() => {
        setDataFilter((prev: any) => ({...prev, dateCompletion: dataFilter.dateCompletion}));
        setLabelFilter((prev: any) => ({
            ...prev,
            dateCompletionLabel: `Срок сдачи ${dataFilter.dateCompletion.length > 0 ? ` <span>(${dataFilter.dateCompletion.length})<span/>` : ''}`
        }))
    }, [dataFilter.dateCompletion]);

    useEffect(() => {
        setDataFilter((prev: any) => ({...prev, salesman: dataFilter.salesman}));
        setLabelFilter((prev: any) => ({
            ...prev,
            salesmanLabel: `Продавец ${dataFilter.salesman.length > 0 ? `<span> (${dataFilter.salesman.length})<span/>` : ''}`
        }))
    }, [dataFilter.salesman]);

    const queryParamsApartment = () => {
        const params: QueryParams = {};

        if (dataFilter.priceStart) params.price_from = dataFilter.priceStart;
        if (dataFilter.priceEnd) params.price_to = dataFilter.priceEnd;
        if (dataFilter.type) params.rooms = dataFilter.type === 'Студия' ? 'Студия' : dataFilter.type;
        if (dataFilter.squareStart) params.area_from = dataFilter.squareStart;
        if (dataFilter.squareEnd) params.area_to = dataFilter.squareEnd;


        return new URLSearchParams(params as Record<string, string>).toString();
    };

    const handleSearch = async () => {
        const query = queryParamsApartment();
        try {
            const response = await getFilterCard(id, query);
            setCountLength(Object.keys(response).length);
            return response;

        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    }

    const handleFiltersCard = async () => {
        try {
            const res = await handleSearch();
            setFilteredCards(res);
            ref.current && ref.current.scrollIntoView({behavior: "smooth", block: "start"});
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const res = await handleSearch();
            setFilteredCards(res);
        }
        fetchData()
    }, []);


    useEffect(() => {
        const debouncedSearch = debounce(async () => {
            await handleSearch();
        }, 500);

        debouncedSearch();

        return () => {
            debouncedSearch.clear();
        };
    }, [dataFilter]);

    return <div className={'filters'}>
        <div className="filters__body">
            <div className="filters__attr">
                {!isMobile && <div className="filters__types">
                    {filterOptions.map(({name}) => (
                        <FilterItem
                            key={name}
                            name={name}
                            type="main"
                            isActive={selectedMain === name}
                            onClick={() => {
                                setSelectedMain(name as 'Комнаты' | 'Студия');
                                setSelectedRoom(name !== 'Студия' ? selectedRoom : null);
                                setDataFilter((prev: any) => ({
                                    ...prev,
                                    type: name === 'Комнаты' ? selectedRoom : name
                                }))
                            }}
                        />
                    ))}
                    {roomOptions.map(({name}) => (
                        <FilterItem
                            key={name}
                            name={name}
                            type="room"
                            isActive={selectedRoom === name}
                            onClick={() => {
                                setSelectedRoom(name as '1' | '2' | '3' | '4+');
                                setSelectedMain('Комнаты');
                                setDataFilter((prev: any) => ({
                                    ...prev,
                                    type: name === '4+' ? '4' : name
                                }))
                            }}
                        />
                    ))}
                </div>}
                {isMobile && <DropdownFilter label={selectedMobile}
                                             isActiveProp={isDropdownActive}
                                             onToggle={(isActive: boolean) => setIsDropdownActive(isActive)}>
                    {(closeDropdown: () => void) => mobileOptions.map(({name}, ind) => {
                        return <li key={ind}>
                            <button
                                onClick={() => {
                                    setSelectedMobile(name as 'Комнаты' | 'Студия' | '1' | '2' | '3' | '4+');
                                    setIsDropdownActive(true);
                                    setDataFilter((prev: any) => ({
                                        ...prev,
                                        type: name === 'Комнаты' ? selectedRoom : name || name === '4+' ? '4' : name
                                    }))
                                }}
                                className={'filters__mobile-top'}>{name}</button>
                        </li>
                    })}
                </DropdownFilter>}
                <DropdownFilter label={labelFilter.priceLabel}>
                    {(closeDropdown: () => void) => (<>
                        <li>
                            <input
                                type="number"
                                name={'start-price'}
                                placeholder="От"
                                min={1}
                                value={dataFilter.priceStart}
                                onChange={e => setDataFilter((prev) => ({
                                    ...prev,
                                    priceStart: e.target.value
                                }))}
                                onKeyDown={e => handlePriceKeyDown(e, closeDropdown)}
                                ref={el => inputRefs.current[0] = el}
                            />
                        </li>
                        <li>
                            <input
                                type="number"
                                name={'end-price'}
                                placeholder="До"
                                min={1}
                                value={dataFilter.priceEnd}
                                onChange={e => setDataFilter((prev) => ({
                                    ...prev,
                                    priceEnd: e.target.value
                                }))}
                                onKeyDown={e => handlePriceKeyDown(e, closeDropdown)}
                                ref={el => inputRefs.current[1] = el}/>
                        </li>
                    </>)
                    }
                </DropdownFilter>

                <DropdownFilter label={labelFilter.squareLabel}>
                    {(closeDropdown: () => void) => (<>
                        <li>
                            <input
                                type="number"
                                name={'start-square'}
                                placeholder="От"
                                min={1}
                                value={dataFilter.squareStart}
                                onChange={e => {
                                    if (e.target.value.length <= 4) {
                                        setDataFilter((prev) => ({
                                            ...prev,
                                            squareStart: e.target.value
                                        }));
                                    }
                                }}
                                onKeyDown={e => handleSquareKeyDown(e, closeDropdown)}
                                ref={el => inputRefs.current[2] = el}/>
                        </li>
                        <li>
                            <input
                                type="number"
                                name={'end-square'}
                                placeholder="До"
                                min={1}
                                value={dataFilter.squareEnd}
                                onChange={e => {
                                    if (e.target.value.length <= 4) {
                                        setDataFilter((prev) => ({
                                            ...prev,
                                            squareEnd: e.target.value
                                        }));
                                    }
                                }}
                                onKeyDown={e => handleSquareKeyDown(e, closeDropdown)}
                                ref={el => inputRefs.current[3] = el}
                            />
                        </li>
                    </>)}
                </DropdownFilter>
                <DropdownFilter label={labelFilter.dateCompletionLabel}>
                    {(closeDropdown: () => void) => (
                        dateOptions.map((option: any, index: number) => (
                            <li key={index}>
                                <label className="form-label-input-wrapper">
                                    <input
                                        type="checkbox"
                                        name="date-completion"
                                        value={option}
                                        checked={dataFilter.dateCompletion.includes(option)}
                                        onChange={handleDateCompletionChange}
                                    />
                                    <div className="form-label-input">
                                        <span>{option}</span>
                                    </div>
                                </label>
                            </li>
                        )))}
                </DropdownFilter>

                <DropdownFilter label={labelFilter.salesmanLabel}>
                    {(closeDropdown: () => void) => (
                        salesmanOptions.map((option: any, index: number) => (
                            <li key={index}>
                                <label className="form-label-input-wrapper">
                                    <input
                                        type="checkbox"
                                        name="salesman"
                                        value={option}
                                        checked={dataFilter.salesman.includes(option)}
                                        onChange={handleSalesmanChange}
                                    />
                                    <div className="form-label-input">
                                        <span>{option}</span>
                                    </div>
                                </label>
                            </li>
                        ))
                    )}

                </DropdownFilter>
            </div>
            <button className="filters__search" onClick={handleFiltersCard}>Показать {countLength} предложений</button>
        </div>

    </div>
})

export default Filters;