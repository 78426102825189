import Slider, {Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';
import {useEffect, useRef, useState} from "react";
import Skeleton from "../skeleton";

const SingleGallery = ({images, loading}: any) => {
    const [navFront, setNavFront] = useState<Slider | null>(null);
    const [navThumbnail, setNavThumbnail] = useState<Slider | null>(null);

    const frontSlider = useRef<Slider | null>(null);
    const thumbnailSlider = useRef<Slider | null>(null);
    const NextArrow = ({onClick}: any) => {
        return <button className={`gallery-arrow gallery-next`} onClick={onClick}>→</button>
    };
    const PrevArrow = ({onClick}: any) => {
        return <button className={`gallery-arrow gallery-prev`} onClick={onClick}>←</button>
    };

    useEffect(() => {
        setNavFront(frontSlider.current);
        setNavThumbnail(thumbnailSlider.current);
    }, []);

    const handleThumbnailClick = (index: number) => frontSlider.current?.slickGoTo(index);

    const settingsMain: Settings = {
        asNavFor: navThumbnail || undefined,
        arrows: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
    };

    const settingsThumbs: Settings = {
        asNavFor: navFront || undefined,
        slidesToShow: 6,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: false,
    };
    return !loading ? images && <>
        <div className="slider-wrapper">
            <Slider
                {...settingsMain}
                ref={frontSlider}
                className={'slider-front'}
            >
                {images?.map(({image}: { image: string }, index: number) => (
                    <div key={index} className="slick-slide slick-slide__front">
                        <img src={`https://novactive.homes${image}`} className="slick-slide-image"/>
                    </div>
                ))}
            </Slider>
        </div>
        <div className="thumbnail-slider">
            <Slider
                {...settingsThumbs}
                ref={thumbnailSlider}
            >
                {images?.map(({thumb}: { thumb: string }, index: number) => (
                    <div key={index}
                         className="slick-slide slick-slide__thumbnail"
                         onClick={() => handleThumbnailClick(index)}>
                        <img src={`https://novactive.homes${thumb}`} className="slick-slide-image"/>
                    </div>
                ))}
            </Slider>
        </div>
    </> : <Skeleton height={'100%'}/>
}

export default SingleGallery;