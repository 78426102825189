import './style.scss'
import FavoriteIcon from "../../../../shared/assets/icons/favorite-icon";
import CompareIcon from "../../../../shared/assets/icons/compare-icon";
import {Link, useLocation, useNavigate} from "react-router-dom";

export const formatPrice = (value: number) => {
    return `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
};

export const calculatePricePerSquareMeter = (price: any, totalArea: any): string => {
    if (!price || !totalArea || totalArea === 0) {
        return '';
    }

    const pricePerSqM = price / totalArea;
    return formatPrice(+pricePerSqM.toFixed());
};

export const isRoom = (val: any) => {
    const numericValues = ['1', '2', '3', '4', '5'];
    const specialValues = [
        'Студия',
        'Пентхаус',
        'Таунхаус',
        'Квадруплекс',
        'Лофт',
        'двухуровневый хай-флет'
    ];
    if (numericValues.includes(val)) {
        return val === '1' ? `${val} комнатная` : `${val}-x комнатная`;
    }

    if (specialValues.includes(val)) {
        return val;
    }
}
const Card = (item: any) => {
    const {id, price, name, title, image, floor, area, rooms, dataLayout}: {
        id: number,
        image: string,
        area: { name?: string, value: string },
        floor: { name?: string, value: string },
        rooms: { name?: string, value: string },
        name: string,
        source_layout_temp__floor: string,
        source_layout_temp__total_floors: string,
        dataLayout: any,
        title: string,
        price: number,
        dataArr?: any
    } = item;
    const {pathname} = useLocation();
    const isObject = pathname.includes('/object');
    const layout_fields = dataLayout ?? item['layout_fields'];
    const getFieldValue = (source: string) => {
        return layout_fields?.find((item: any) => item.source === source)?.data?.value;
    };

    const dataHandler = (id: any) => {
        window.scrollTo(0, 0);
        const cardsLayout = !isObject ?
            layout_fields.find((item: any) => item.id == id)
            : layout_fields;
        localStorage.setItem("layout_fields", JSON.stringify(item['layout_fields'] ?? cardsLayout['layout_fields']))
    };
    return <article className={'apartment'}>
        <button className="apartment__favorites">
            <FavoriteIcon/>
        </button>
        <button className="apartment__compare">
            <CompareIcon/>
        </button>
        {(image || getFieldValue("image")) && <div className="apartment__block-image">
            <img src={`https://novactive.homes${image ?? getFieldValue("image")}`} alt={`${getFieldValue("title")}`}/>
        </div>}
        <div className="apartment__body">
            {name || title && <h3 className="apartment__title">{name || title}</h3>}
            <div className="apartment__price">{formatPrice(price)} руб.</div>
            <div
                className="apartment__meter">{
                calculatePricePerSquareMeter(price ?? getFieldValue('price'), item['source_layout_temp__total_area'] ?? area?.value ?? getFieldValue('source_layout_temp__total_area'))
            } /м2
            </div>
            <div className="apartment__info info-items">
                <div className="info-items__item">
                    <div className="info-items__name">Тип квартиры:</div>
                    <div
                        className="info-items__value">{isRoom(item['source_layout_temp__room_number'] ?? rooms?.value ?? getFieldValue("source_layout_temp__room_number"))}</div>
                </div>
                {(item['source_layout_temp__total_area'] || area?.value || getFieldValue("source_layout_temp__total_area")) &&
                    <div className="info-items__item">
                        <div className="info-items__name">Площадь:</div>
                        <div
                            className="info-items__value">{item['source_layout_temp__total_area'] ?? area?.value ?? getFieldValue("source_layout_temp__total_area")} м²
                        </div>
                    </div>}
                <div className="info-items__item">
                    <div className="info-items__name">Этажность:</div>
                    <div
                        className="info-items__value">{item['source_layout_temp__floor'] ?? floor?.value ?? getFieldValue("source_layout_temp__floor")} из {item['source_layout_temp__total_floors'] ?? item['floors-total']?.value ?? getFieldValue("source_layout_temp__total_floors")}</div>
                </div>
            </div>
            <div className="apartment__btns">
                <a href={`#`} className="apartment__more">Подробнее</a>
                <Link to={`/object/${id}`} className="apartment__select" onClick={() => dataHandler(id)}>Подобрать
                    обьект</Link>
            </div>
        </div>
    </article>
}

export default Card