import './style.scss';
const Skeleton = ({ width, height, borderRadius, className = '' }: any) => {
    const style = {
        width: width || '100%',
        height: height || '1em',
        borderRadius: borderRadius || '4px',
    };

    return <div className={`skeleton ${className}`} style={style}></div>;
};

export default Skeleton;