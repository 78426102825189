import './style.css'
import ProcessPopap from "../Popup/Process";
import ProcessSlider from "../../../../entity/main-page/components/process-slider";
import React, {useEffect, useRef, useState} from "react";
import useModal from "../../../../shared/hooks/useModal";

const sliderData = [
    {
        img: '/img/process1.png',
        alt: 'квартиры'
    },
    {
        img: '/img/process2.png',
        alt: 'квартиры'
    },
    {
        img: '/img/process3.png',
        alt: 'квартиры'
    },
    {
        img: '/img/process4.png',
        alt: 'квартиры'
    },
];
const processList = [
    {
        name: 'Обратитесь в АН НОВАКТИВ'
    },
    {
        name: 'Организуем встречу с агентом'
    },
    {
        name: 'Выбираем подходящий вариант'
    },
    {
        name: 'Экскурсия в разные ЖК'
    },
    {
        name: 'Оформим ипотеку или выберем расчёт'
    },
    {
        name: 'Оформим документы'
    },
    {
        name: 'Празднуем новоселье!'
    },
]
const Process = () => {
    ;

    const formRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const {isOpen, openModal, closeModal} = useModal();


    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (formRef.current && !formRef.current.contains(event.target as Node)) {
            closeModal();
        }
    };

    return <section className="process" id="process">
        <div className="container">
            <div className="process-body">
                <div className="process-body-header">
                    <h2 className="process-title title">
                        <span>Процесс</span>
                        <p>Подбора квартиры</p>
                    </h2>
                    <button className="process-body-header-button button" onClick={openModal}>Оставить заявку</button>
                </div>
                <div className="process-wrapper">
                    <div className="process-list">
                        {processList.map((item: { name: string }, ind: number) => {
                            const num = ind + 1;
                            return <p key={ind}><span>{num > 9 ? ind : '0' + num}</span>{item.name}</p>
                        })}
                    </div>
                    <ProcessSlider data={sliderData}/>
                    <div className="process-footer">
                        <img src="/img/process-mobile.png" alt="квартиры"/>
                        <button className="process-footer-button button" onClick={openModal}>Оставить заявку</button>
                    </div>
                </div>
                {isOpen && <div
                    className="overlay active"
                    ref={overlayRef}
                    onClick={handleOverlayClick}>
                    <ProcessPopap ref={formRef}/>
                </div>}
            </div>
        </div>
    </section>
}

export default Process;