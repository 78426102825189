import ShareIcon from "../../../../shared/assets/icons/share-icon";
import './style.scss'
import {FC, ReactNode} from "react";

interface IActionButtonProps {
    title?: string;
    icon?: ReactNode;
    onClick?: () => void | Promise<void>;
}
const ActionButton: FC<IActionButtonProps>  = ({
                          title = 'Поделиться',
                          icon = <ShareIcon/>,
                          onClick = () => null,
                      }) => {
    return <button className={'share'} onClick={onClick}>
        {icon}
        {title}
    </button>
}

export default ActionButton