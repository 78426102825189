import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {forwardRef} from "react";
import ReviewItem from "../reviews-item";

const ReviewsSlider = forwardRef(({reviews}: any, ref: any) => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1284,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Slider ref={ref} {...settings} className="reviews__slider">
            {reviews.map((review: any, index: number) => (
                <ReviewItem key={index} review={review} />
            ))}
        </Slider>
    );
});



export default ReviewsSlider;