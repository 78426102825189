import './style.css';
import React, {useState} from "react";
import InputMask from "react-input-mask";

export const areas = ['Район 1', 'Район 2', 'Район 3'];
export const prices = ['от 3 до 5 млн.', 'от 5 до 10 млн.', 'от 10 и более млн.'];
type FormData = {
    name: string;
    phone: string;
    conditions: string[];
    district: string,
    dataConsent: boolean,
    contactMethod: string,
    budget: string
};
const FirstBlock = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        phone: '',
        budget: '',
        contactMethod: '',
        dataConsent: false,
        district: '',
        conditions: []
    });
    const [error, setError] = useState('');
    const [phone, setPhone] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value, type, checked} = e.target as HTMLInputElement;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleConditionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked, value} = e.target;
        setFormData((prev: any) => ({
            ...prev,
            conditions: checked
                ? [...prev.conditions, value]
                : prev.conditions.filter((cond: any) => cond !== value)
        }));
    };

    const validateForm = () => {
        const nameRegex = /^[А-ЯЁа-яё]{2,40}$/;
        const phoneRegex = /^(\+7|8)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/;

        if (!formData.name || !nameRegex.test(formData.name)) {
            setError('Некорректное имя');
            return false;
        }

        if (!phoneRegex.test(formData.phone)) {
            setError('Некорректный номер телефона');
            return false;
        }

        if (!formData.budget || !formData.contactMethod || !formData.dataConsent || !formData.district) {
            setError('Не все поля заполнены');
            return false;
        }

        if (formData.conditions.length === 0) {
            setError('Не выбрано дополнительное условие');
            return false;
        }

        setError('');
        return true;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Форма успешно отправлена', formData);
            alert('Форма успешно отправлена');
        }
    };
    return <section className="first-block">
        <div className="first-block-img">
            <img src="/img/firstblock-bg.png" alt=""/>
        </div>
        <div className="first-block-form">
            <h1 className="first-block-title">Ваш лучший помощник <br/>в подборе Новостроек <br/>в Новосибирске</h1>
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-label-row">
                    <p className="form-label-title">Бюджет</p>
                    <div className="form-label-flex">
                        {prices.map((budget, index) => (
                            <label key={index} className="form-label-input-wrapper">
                                <input
                                    type="radio"
                                    name={`budget`}
                                    value={budget}
                                    checked={formData.budget === budget}
                                    onChange={handleInputChange}
                                />
                                <div className="form-label-input">
                                    <span>{budget}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>

                <div className="form-label-row">
                    <p className="form-label-title">Дополнительные условия</p>
                    <div className="form-label-flex form-label-flex-column">
                        {['есть первоначальный взнос', 'подхожу под субсидированную ипотеку'].map((condition, index) => (
                            <label key={index} className="form-label-input-wrapper">
                                <input
                                    type="checkbox"
                                    name={`conditions`}
                                    value={condition}
                                    checked={formData.conditions.includes(condition)}
                                    onChange={handleConditionChange}
                                />
                                <div className="form-label-input">
                                    <span>{condition}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>

                <div className="form-label-row form-label-flex flex-column">
                    <div className="form-label-select">
                        <p className="form-label-title">Район</p>
                        <select
                            name="district"
                            value={formData.district}
                            onChange={handleInputChange}
                            className="form-label-select-content"
                        >
                            <option value="">Название района</option>
                            {areas.map((district, index) => (
                                <option key={index} value={district}>
                                    {district}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-label-select">
                        <p className="form-label-title">Количество комнат</p>
                        <select className="form-label-select-content">
                            <option value="studia">Студия</option>
                            {[...Array(6)].map((_, ind) => (
                                <option key={ind} value={ind}>{ind}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-label-row form-label-flex flex-column">
                    <div className="form-label-text">
                        <input
                            type="text"
                            name="name"
                            placeholder="Имя"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-label-text">
                        <InputMask
                            mask="+7 999 999 99 99"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                handleInputChange(e)
                            }}
                            className="phone-input"
                            placeholder="Телефон"
                        >
                            {(inputProps: any) => (
                                <input
                                    type="tel"
                                    name="phone"
                                    {...inputProps}
                                    className="form__phone"
                                />
                            )}
                        </InputMask>
                    </div>
                </div>

                {error && <div className="formError">{error}</div>}

                <div className="form-label-row">
                    <p className="form-label-title">Куда отправить подбор</p>
                    <div className="form-label-flex">
                        {['Telegram', 'Whatsapp'].map((method, index) => (
                            <label key={index} className="form-label-input-wrapper">
                                <input
                                    type="radio"
                                    name="contactMethod"
                                    value={method}
                                    checked={formData.contactMethod === method}
                                    onChange={handleInputChange}
                                />
                                <div className="form-label-input">
                                    <span>{method}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>


                <button type="submit" className="form__button button">
                    Подобрать квартиру
                </button>
                <label className="form-label-flex form-label-politic">
                    <input
                        type="checkbox"
                        name="dataConsent"
                        checked={formData.dataConsent}
                        onChange={handleInputChange}
                    />
                    <span>Я даю согласие на обработку своих персональных данных</span>
                </label>
            </form>
        </div>
    </section>
}

export default FirstBlock;