import axios from 'axios'

export const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access') || ''
    if (token) config.headers!['Authorization'] = 'Bearer ' + token
    return config
  },
  (error) => Promise.reject(error)
)

http.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config

    if (originalConfig.url !== '/' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const rs = await http.post('/token/refresh', {
            Token: localStorage.getItem('refresh') || '',
          })

          console.log('🚀  !@#$ ~ rs:', rs)

          return http(originalConfig)
        } catch (_error) {
          localStorage.removeItem('tokens')
          if (window.location.pathname !== '/') {
            window.location.assign('/')
          }
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)
