import {useState} from "react";

const Tabs = ({tabs, setActive, activeTab}: {tabs: any, setActive: any, activeTab: any}) => {
    return <div className="tabs partners__buttons-left">
        {tabs.map((tab: { name: string }, ind: number) => <button key={ind}
            className={`partners__button ${activeTab === ind ? 'is-active' : ''}`}
            onClick={() => setActive(ind)}
        >
            {tab.name}
        </button>)}

    </div>
}

export default Tabs