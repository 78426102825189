import {Link} from "react-router-dom";
import {calculatePricePerSquareMeter} from "../../../singel-card/components/card-apartment";

type constructionData ={
    field_settings_id: number,
    field_type?: string,
    options?: string[],
    source: string,
    title: string,
    value?: number
}

const ComfortItem = ({title, price, metro, image, id, fields}: any) => {
    const getFieldValue = (source: string) => {
        return fields?.find((item: constructionData) => item.source === source)?.data?.value;
    };
console.log('fields', fields)
    return <div className="comfort-item">
        <div className="comfort-item-img"
             style={{backgroundImage: `url(${image})`}}></div>
        <div className="comfort-item-info">
            <p className="comfort-item-info-title">{title}</p>
            <div className="comfort-item-info-content">
                <div className="comfort-item-info-content-left comfort-item-info-content-text">
                     <p className="comfort-item-info-text">
                        <svg width="17" height="19" viewBox="0 0 17 19" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6.34285L8.5 1L16 6.34285V18H1V6.34285Z" stroke="#141414"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {getFieldValue("source_temp__apartment_deadline") ?
                            `${getFieldValue("source_temp__apartment_deadline")} ${getFieldValue("source_temp__apartment_year")}`
                            : ' Проект не сдан'}
                    </p>
                    {/*<p className="comfort-item-info-text">Цена за м2: {calculatePricePerSquareMeter(price,getFieldValue() ) }</p>*/}
                    {metro && <p className="comfort-item-info-text">От метро: {metro}</p>}
                </div>
                <div className="comfort-item-info-content-right comfort-item-info-content-text">
                     <p className="comfort-item-info-text">
                        <svg width="17" height="19" viewBox="0 0 17 19" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6.34285L8.5 1L16 6.34285V18H1V6.34285Z" stroke="#141414"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                         {getFieldValue("source_temp__apartment_deadline") ?
                             `${getFieldValue("source_temp__apartment_deadline")} ${getFieldValue("source_temp__apartment_year")}`
                             : ' Проект не сдан'}
                    </p>
                    <p className="comfort-item-info-text">Цена за м2: {price}</p>
                    {metro && <p className="comfort-item-info-text">От метро: {metro}</p>}
                </div>
            </div>
        </div>
        <Link to={`/apartment/${id}`} className="comfort-item-btn button" data-title="«Поколение»">Подробнее</Link>
    </div>
}

export default ComfortItem;