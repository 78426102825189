import './style.css';
import React, {useEffect, useRef, useState} from "react";
import {areas, prices} from "../FirstBlock";
import InputMask from "react-input-mask";
import Form from "../../../../entity/main-page/components/form";

const methodList = [
    {title: "Подберем квартиру в ипотеку", label: "Ипотека"},
    {title: "Подберем квартиру с собственными средствами", label: "Собственные средства"},
    {title: "Подберем квартиру по трейд-ин", label: "Трейд ин"},
    {title: "Подберем квартиру в рассрочку", label: "Рассрочка от застройщика"},
    {title: "Подберем квартиру с обменом на коммерцию", label: "Обмен на коммерцию"},
    {title: "Подберем квартиру по жилищному сертификату", label: "Жилищный сертификат"},
];


const BuyingMethod = () => {
    const [isModalActive, setIsModalActive] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const headerRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        headerRef.current = document.querySelector('.header');
    }, []);

    const handleOpenModal = (title: string) => {
        setModalTitle(title);
        setIsModalActive(true);
        document.body.classList.add("no-scroll");
        if (headerRef.current) {
            headerRef.current.classList.add('header-hidden');
        }
    };

    const handleCloseModal = () => {
        setIsModalActive(false);
        document.body.classList.remove("no-scroll");
        if (headerRef.current) {
            headerRef.current.classList.remove('header-hidden');
        }
    };

    return <section className="buying__method" id="buying__method">
        <div className="buying__container">
            <div className="buying__method-content">
                <div className="sidebar">
                    <h2 className="buying__method-title title">
                        <span>Способы</span>
                        <p>Покупки</p>
                    </h2>
                    <ul className="buying__method-list">
                        {methodList.map((item, index) => (
                            <li key={index}>
                                <button
                                    className="buying__button"
                                    onClick={() => handleOpenModal(item.title)}
                                >
                                    {item.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {isModalActive && <>
                    <div className="overlay active" onClick={handleCloseModal}></div>
                    <Form title={modalTitle}/>
                </>}
            </div>
        </div>
    </section>
}

export default BuyingMethod