import {areas, prices} from "../../../../pages/main/components/FirstBlock";
import InputMask from "react-input-mask";
import React, {useState} from "react";

import './style.css';
import Success from "../../../../pages/main/components/Popup/Success";

type formData = {
    name: string,
    phone: string,
    budget: string,
    contactMethod: string,
    conditions: string[],
    district: string,
    dataConsent: boolean,
    qty: string
}

const Form = ({title = 'Подберем квартиру', modal = false}: { title?: string, modal?: boolean }) => {
    const [formData, setFormData] = useState<formData>({
        name: "",
        phone: "",
        budget: "",
        contactMethod: "",
        conditions: [],
        district: "",
        qty: '',
        dataConsent: false,
    });
    const [error, setError] = useState('');
    const [phone, setPhone] = useState('')
    const [successVisible, setSuccessVisible] = useState(false);
    const handleChange = (e: any) => {
        const {name, value, type, checked} = e.target;
        setFormData((prev) => {
            if (name === "conditions" && type === "checkbox") {
                const updatedConditions = checked
                    ? [...(prev.conditions || []), value]
                    : (prev.conditions || []).filter((condition) => condition !== value);

                return {
                    ...prev,
                    conditions: updatedConditions,
                };
            }

            return {
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            };
        });
    };


    const validateNameAndNumber = () => {
        const nameRegex = /^[А-ЯЁа-яё]{2,40}$/;
        const phoneRegex = /^(\+7|8)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/;

        if (!nameRegex.test(formData.name)) {
            setError("Некорректное имя");
            return false;
        }

        if (!phoneRegex.test(formData.phone)) {
            setError("Некорректный номер телефона");
            return false;
        }

        return true;
    };


    const validateForm = (e: any) => {
        e.preventDefault();
        setError("");
        console.log('formData', formData)
        if (
            !formData.district ||
            !formData.budget ||
            !formData.contactMethod ||
            !formData.qty ||
            !formData.dataConsent ||
            formData.conditions.length === 0
        ) {
            setError("Не все поля заполнены");
            return false;
        }

        if (!validateNameAndNumber()) {
            return false;
        }

        return true;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(e)) {
            console.log("Форма успешно отправлена!", formData);
            setSuccessVisible(true);
            setFormData({
                name: "",
                phone: "",
                budget: "",
                contactMethod: "",
                conditions: [],
                district: "",
                qty: '',
                dataConsent: false,
            })
            setPhone('')
        }
    };

    return <>
        <form className={`${modal ? "popap__form active" : "form active"}`} onSubmit={handleSubmit}>
        <div className="form__container">
            <div className={`${modal ? "popap__form__form-title" : "form-title"}`}>
                <h2 className="form-title-header">{title}</h2>
                <p>Укажите ваши пожелания и мы подберем идеальный вариант</p>
            </div>
            <div className="form-label-row">
                <p className="form-label-title">Бюджет</p>
                <div className="form-label-flex">
                    {prices.map((budget, idx) => (
                        <label key={idx} className="form-label-input-wrapper">
                            <input
                                type="radio"
                                name="budget"
                                value={budget}
                                onChange={handleChange}
                                checked={formData.budget === budget}
                            />
                            <div className="form-label-input">
                                <span>{budget}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            <div className="form-label-row">
                <p className="form-label-title">Дополнительные условия</p>
                <div className="form-label-flex form-label-flex-column">
                    {["есть первоначальный взнос", "подхожу под субсидированную ипотеку"].map((condition, idx) => (
                            <label key={idx} className="form-label-input-wrapper">
                                <input
                                    type="checkbox"
                                    name="conditions"
                                    value={condition}
                                    onChange={handleChange}
                                    checked={formData.conditions.includes(condition)}
                                />
                                <div className="form-label-input">
                                    <span>{condition}</span>
                                </div>
                            </label>
                        )
                    )}
                </div>
            </div>
            <div className="form-label-column form-label-flex">
                <div className="form-label-select">
                    <p className="form-label-title">Район</p>
                    <select className="form-label-select-content" name="district" value={formData.district}
                            onChange={handleChange}>
                        <option value="">Название района</option>
                        {areas.map((district, index) => (
                            <option key={index} value={district}>
                                {district}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-label-select">
                    <p className="form-label-title">Количество комнат</p>
                    <select className="form-label-select-content" name={'qty'} value={formData.qty}
                            onChange={handleChange}>
                        <option value="studia">Студия</option>
                        {[...Array(6)].map((_, ind) => (
                            <option key={ind} value={ind + 1}>{ind + 1}</option>
                        ))}
                    </select>
                </div>
                <div className="form-label-text">
                    <input type="text"
                           name="name"
                           placeholder="Имя"
                           value={formData.name}
                           onChange={handleChange}/>
                </div>
                <div className="form-label-text">
                    <InputMask
                        mask="+7 999 999 99 99"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                            handleChange(e);
                        }}
                        className="phone-input"
                        placeholder="Телефон"
                    >
                        {(inputProps: any) => (
                            <input
                                type="tel"
                                name="phone"
                                {...inputProps}
                                className="form__phone"
                            />
                        )}
                    </InputMask>
                </div>
            </div>

            {error && <div className="formError">{error}</div>}

            <div className="form-label-row">
                <p className="form-label-title">Куда отправить подбор</p>
                <div className="form-label-flex form-label-befor-block">
                    {["Telegram", "Whatsapp"].map((method, idx) => (
                        <label key={idx} className="form-label-input-wrapper">
                            <input
                                type="radio"
                                name="contactMethod"
                                value={method}
                                onChange={handleChange}
                                checked={formData.contactMethod === method}
                            />
                            <div className="form-label-input">
                                <span>{method}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            <button type="submit" className="button form__button">Подобрать квартиру</button>
            <label className="form-label-flex form-label-politic">
                <input
                    type="checkbox"
                    name="dataConsent"
                    checked={formData.dataConsent}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            dataConsent: e.target.checked,
                        }))
                    }
                />
                <span>Я даю согласие на обработку своих персональных данных</span>
            </label>
        </div>
    </form>
        {successVisible && <Success onClose={() => setSuccessVisible(false)}/>}
    </>
}

export default Form