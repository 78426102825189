import './style.scss';
import {useState} from "react";

const Description = ({text, title = 'Описание'}: any) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleText = () => {
        setIsExpanded((prev: any) => !prev);
    };

    return text && <div className={`description ${isExpanded ? 'active' : ''}`}>
        {title && <h2 className="title-block">{title}</h2>}
        {typeof text === 'object' ?
            <p className={`description__text ${isExpanded ? 'active' : ''}`}>{text}</p> :
            <p className={`description__text ${isExpanded ? 'active' : ''}`} dangerouslySetInnerHTML={{__html: text}}/>
        }
        <button className="description__more" onClick={toggleText}>
            {isExpanded
                ? "Свернуть"
                : "Показать больше"}</button>
    </div>
}

export default Description