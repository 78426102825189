import React from "react";



const Success = ({ onClose }: any) => {
    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return <>
        <div className="overlay" onClick={handleOverlayClick} style={{visibility: "visible"}}> </div>
        <section className="success active">
            <div className="success__wrapper">
                <h2>Мы уже работаем с вашим запросом</h2>
                <p>Наш агент вскоре свяжется с вами!</p>
            </div>
        </section>

    </>
}

export default Success;