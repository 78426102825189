import './style.css'
import OvalDecor from "../../../../shared/assets/icons/oval-decor";

const About = () => {
    return <section className="about">
        <div className="container">
            <div className="about-body">
                <h2 className="about-header">
                    <img src="/img/logo-yellow.png" alt=""/>
                    <p>ваш надежный <br/>партнер</p>
                </h2>
                <div className="about-text-block">
                    <OvalDecor/>
                    <p className="about-text-title">Миссия</p>
                    <p className="about-text">Мы профессионально предлагаем наилучшие решения в сфере недвижимости</p>
                    <p className="about-text">Увеличиваем активы наших клиентов. Мы любим то, что мы делаем!</p>
                </div>
                <div className="about-text-block">
                    <OvalDecor/>
                    <p className="about-text-title">Бренд</p>
                    <p className="about-text">Новактив - это сообщество профессиональных агентов по жилой
                        недвижимости</p>
                    <p className="about-text">Наши партнеры это ведущие банки и застройщики. Мы всегда рады предложить
                        нашим
                        клиентам индивидуальные условия покупки.</p>
                </div>
            </div>
            <div className="about-numbers">
                <p>
                    4
                    <span>года на рынке <br/>недвижимости</span>
                </p>
                <p>
                    &gt;350
                    <span>довольных <br/>семей</span>
                </p>
                <p>
                    7264,24
                    <span>кв.м продано</span>
                </p>
            </div>
        </div>
    </section>
}

export default About;