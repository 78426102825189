import Card, {calculatePricePerSquareMeter} from '../../entity/singel-card/components/card-apartment'
import {typeObject} from '../single'
import Banner from '../single/components/Banner'
import FormSign from '../single/components/FormSign'
import Specialist from '../single/components/Specialist'
import FeedBack from './modules/feedback/feedback'
import SimilarOffer from './modules/similar-offers/similar-offers'
import {useEffect, useState} from "react";

import SingleInfo from "../single/components/SingleInfo";
import Description from "../single/components/Description";
import ActionButton from "../../entity/singel-card/components/action-button";
import FavoriteButtonIcon from "../../shared/assets/icons/favorite-button-icon";
import CompareButtonIcon from "../../shared/assets/icons/compare-button-icon";
import Attributes from "../../entity/object/attributes";
import Maps from "./components/map";
import {useNavigate, useParams} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import SliderButton from "./ui/buttons/slider-button/slider-button";
import {getCardsObjectLayout} from "../../shared/api/endpoints";

const CurrentApartment = () => {
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const data = JSON.parse(localStorage.getItem("layout_fields") ?? "{}");
    const [cards, setCards] = useState<any>([])
    const [shareText, setShareText] = useState('Поделиться');
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();
    useEffect(() => {
        if (!localStorage.getItem("layout_fields")) {
            navigate('/');
            localStorage.removeItem("layout_fields")
        }
        window.scrollTo(0, 0);

    }, []);
    useEffect(() => {
        if (isLoading) return;
        setIsLoading(true);
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const data = await getCardsObjectLayout(id);
                setCards(data.results);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        }
        fetchData()
    }, [])
    const layoutFields = cards?.area ? cards.map((item: any) => item['layout_fields']) : cards;
    const getFieldValue = (source: string, isTextValue = false) => {
        const dataField = !isTextValue ? data?.find((item: typeObject) => item.source === source)?.data?.value
            : data?.find((item: typeObject) => item.source === source)?.data?.text_value;
        return dataField;
    };
    const shareHandler = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            setShareText('Скопировано!');
        } catch (error) {
            setShareText('Ошибка копирования');
        } finally {
            setTimeout(() => setShareText('Поделиться'), 3000);
        }
    }
    return (
        <>
            <Banner
                title={getFieldValue('title')}
                price={getFieldValue('price')}
                apartmentDeadline={getFieldValue('source_temp__apartment_deadline')}
                apartmentYear={getFieldValue('source_temp__apartment_year')}
            />
            <section className={'single'}>
                <div className='container single__container'>
                    <div className='single__product single__product_apartment'>
                        <div className='single__info'>
                            <Maps
                                imagesLayout={getFieldValue('image')}
                                coordinates={getFieldValue('coordinates')}
                                areas={getFieldValue('source_layout_temp__total_area')}
                                room={getFieldValue('source_layout_temp__room_number')}
                            />
                        </div>
                        <div className='single__right single__right_apartment'>

                            <Specialist
                                name={'Алла Сорокина'}
                                job={'Специалист по жилой недвижимости'}
                                img={'/img/special.png'}
                            />
                        </div>
                    </div>
                    <div className='single__bottom single__product'>
                        <div className='single__data single__info'>
                            <h2 className="title-block">Общая информация</h2>
                            <div className="single__prices single__prices_apartment">
                                <SingleInfo
                                    price={getFieldValue('price')}
                                    priceMeter={calculatePricePerSquareMeter(getFieldValue('price'), getFieldValue('source_layout_temp__total_area'))}
                                />
                            </div>
                            <div className="single__actions">
                                <ActionButton icon={<FavoriteButtonIcon/>} title={'Избранное'}/>
                                <ActionButton icon={<CompareButtonIcon/>} title={'Сравнить'}/>
                                <ActionButton onClick={shareHandler} title={shareText}/>
                            </div>
                            <Description text={<Attributes getFieldValue={getFieldValue}/>} title={null}/>
                        </div>
                        <div className='single__right single__sign'>
                            <FormSign/>
                        </div>
                    </div>

                </div>
            </section>

            {width > 768 && <FeedBack/>}
            {/*<Construction/>*/}
            {/*<FeedBackRound/>*/}
            {layoutFields.length > 0 && <>
                <SimilarOffer onSortChange={() => null}/>
                <div className='similar-offer__wrappper single__items'>
                    {width <= 768 ? (
                        <Swiper
                            navigation={{
                                nextEl: '.slider-button-right',
                                prevEl: '.slider-button-left',
                            }}
                            spaceBetween={20}
                            slidesPerView={1}
                        >
                            {layoutFields.map((item: any, ind: number) => (
                                <SwiperSlide key={ind}>
                                    <Card {...item} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (

                        layoutFields.map((item: any, ind: number) => (
                            <Card key={ind} {...item} />
                        ))

                    )}

                    <div className='similar-offer__slider-buttons'>
                        <SliderButton className="slider-button-left" arrowDirection={'left'}/>
                        <SliderButton className="slider-button-right" arrowDirection={'right'}/>
                    </div>
                </div>
            </>}
        </>
    )
}

export default CurrentApartment
