import React from "react";

const LogoIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="56" height="16" viewBox="0 0 56 16"
                fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M51.0209 5.70138C50.6521 5.0347 50.1504 4.51963 49.5163 4.15193C48.8814 3.78777 48.173 3.60203 47.3906 3.60203C46.6091 3.60203 45.9008 3.78777 45.2666 4.15193C44.6319 4.51963 44.1306 5.0347 43.762 5.70138C43.3933 6.36796 43.2082 7.13395 43.2082 8.0001C43.2082 8.86142 43.3933 9.62751 43.762 10.2946C44.1306 10.9612 44.6319 11.4805 45.2666 11.8441C45.9008 12.2124 46.6091 12.3939 47.3906 12.3939C48.173 12.3939 48.8814 12.2124 49.5163 11.8441C50.1504 11.4805 50.6521 10.9612 51.0209 10.2946C51.3897 9.62751 51.5745 8.86142 51.5745 8.0001C51.5745 7.13395 51.3897 6.36796 51.0209 5.70138ZM51.8065 14.9652C50.5006 15.6539 49.0288 16 47.3906 16C45.7536 16 44.2824 15.6539 42.9762 14.9652C41.6708 14.2769 40.6446 13.3246 39.9001 12.1081C39.481 11.4243 39.1843 10.6928 39.0011 9.90893H17.2861V14.7575C17.2861 15.2724 16.8643 15.6929 16.3473 15.6929H13.8423C13.3258 15.6929 12.9041 15.2724 12.9041 14.7575V9.90893H6.92883V14.7575C6.92883 15.2724 6.50718 15.6929 5.98992 15.6929H3.48551C2.96911 15.6929 2.5469 15.2724 2.5469 14.7575V9.90893H0.932949C0.422808 9.90893 0 9.49327 0 8.98283V7.23352C0 6.72312 0.422808 6.30746 0.932949 6.30746H2.5469V1.23804C2.5469 0.727192 2.96911 0.307193 3.48551 0.307193H5.98992C6.50718 0.307193 6.92883 0.727192 6.92883 1.23804V6.30746H12.9041V1.23804C12.9041 0.727192 13.3258 0.307193 13.8423 0.307193H16.3473C16.8643 0.307193 17.2861 0.727192 17.2861 1.23804V6.30746H38.9497C39.1255 5.44146 39.4419 4.63641 39.9001 3.88763C40.6446 2.67538 41.6708 1.71842 42.9762 1.0303C44.2824 0.342026 45.7536 0 47.3906 0C49.0288 0 50.5006 0.342026 51.8065 1.0303C53.1126 1.71842 54.1373 2.67538 54.8827 3.88763C55.6275 5.10367 56 6.47642 56 8.0001C56 9.52372 55.6275 10.8918 54.8827 12.1081C54.1373 13.3246 53.1126 14.2769 51.8065 14.9652Z"
              fill="#273E42"/>
    </svg>
}

export default LogoIcon;