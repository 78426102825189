import '../../../main/components/ApartmentSelection/style.css'
import './style.scss'
import React, {useRef, useState} from "react";
import InputMask from "react-input-mask";
import Success from "../../../main/components/Popup/Success";

const FormSign = ({className}: any) => {
    const [formData, setFormData] = useState({
        name: "",
        phone_single: "",
        email: "",
        dataConsent: false,
    });

    const [error, setError] = useState("");
    const [successVisible, setSuccessVisible] = useState(false);
    const [phone, setPhone] = useState('');

    const validateNameAndPhone = () => {
        const nameRegex = /^[А-ЯЁа-яё]{2,40}$/;
        const phoneRegex = /^(\+7|8)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/;

        const isNameValid = nameRegex.test(formData.name.trim());
        const isPhoneValid = phoneRegex.test(formData.phone_single.trim());

        if (!isNameValid) {
            setError("Некорректное имя");
            return false;
        }
        if (!isPhoneValid) {
            setError("Некорректный номер телефона");
            return false;
        }

        return true;
    };

    const validateForm = () => {
        const { dataConsent} = formData;

        if (
            !formData.name ||
            !formData.phone_single ||
            !formData.email ||
            !dataConsent
        ) {
            setError("Не все поля заполнены");
            return false;
        }

        if (!validateNameAndPhone()) {
            return false;
        }

        setError("");
        return true;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value, type, checked} = e.target as HTMLInputElement;

        if (type === "checkbox" && name === "conditions") {
            setFormData((prev: any) => ({
                ...prev,
                conditions: checked
                    ? [...prev.conditions, value]
                    : prev.conditions.filter((condition: any) => condition !== value),
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            setSuccessVisible(true);
            setFormData({
                name: "",
                phone_single: "",
                email: "",
                dataConsent: false,
            })
            setPhone('')
        }
    };

    return <section className="apartment-selection form-single">
        <form className={`form ${className}`} onSubmit={handleSubmit}>
            <div className="form__container">
                <div className="form-title form-single__title">
                    <h2><span><b>Записаться</b> на </span> предпросмотр</h2>
                </div>
                <div className="form-label-column form-label-flex form-single__inputs">
                    <div className="form-label-text">
                        <input
                            type="text"
                            name="name"
                            placeholder="Ваше имя"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-label-text">
                        <InputMask
                            mask="+7 999 999 99 99"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                handleChange(e);
                            }}
                            className="phone-input"
                            placeholder="Ваш номер телефона"
                        >
                            {(inputProps: any) => <input type="tel" name={'phone_single'} {...inputProps}
                                                         className="form__phone"/>}
                        </InputMask>
                    </div>
                    <div className="form-label-text">
                        <input
                            className="form__phone"
                            name="email"
                            type="email"
                            placeholder="Ваш E-mail"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {error && <div className="formError" style={{marginBottom: '5px'}}>{error}</div>}
                <button className="button form__button form-single__btn">Подобрать объект</button>
                <p className="form-single__checked-info">Нажимая кнопку «Подобрать объект», вы подтверждаете свое
                    согласие на обработку персональных данных</p>

                <label className="form-label-flex form-label-politic">
                    <input
                        type="checkbox"
                        name="dataConsent"
                        checked={formData.dataConsent}
                        onChange={handleChange}
                    />
                    <span>Я даю согласие на обработку своих персональных данных</span>
                </label>
            </div>
        </form>
        {successVisible && <Success onClose={() => setSuccessVisible(false)}/>}
    </section>
}

export default FormSign