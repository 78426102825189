import Form from '../../components/form/form'
import './feedback.scss'

const FeedBack = () => {
    return (
        <div className="feedBack__wrapper">
            <div className="feedBack__content">
                <h2 className='feedBack__header'><span>Запишитесь</span>
                    На экскурсию
                </h2>
                <Form isPhone={true} textButton='Записаться' />
            </div>
        </div>
    )
}

export default FeedBack;