import React, {useEffect, useRef, useState} from "react";
import Form from "../form";
import {FALSE} from "sass";
import useModal from "../../../../shared/hooks/useModal";
import {getToken} from "../../../../shared/api/endpoints";

const ExclusiveItem = ({
                           name,
                           price,
                           percent,
                           bid,
                           payment,
                           floor,
                           apartment,
                           mortgage,
                           bidPercent,
                           bidMortgage,
                           imageUrl,
                           imgMobileUrl,
                           date,
                           monthlyPayment
                       }: any) => {
    const {isOpen, openModal, closeModal} = useModal();
    return <>
        <div className="exclusive-item">
            <div className="exclusive-item-text-block">
                {imgMobileUrl && <img className="exclusive-item-top" src={imgMobileUrl} alt=""/>}
                <p className="exclusive-item-title" dangerouslySetInnerHTML={{__html: name}}/>
                <div className="exclusive-item-grid">
                    {date && <p><span>{date}</span></p>}
                    <>
                        {mortgage && <p>{mortgage}%
                            <br/>
                            <span
                                className="exclusive-special-span exclusive-special-span-top">Ипотека</span>
                            <span className="exclusive-special-span">с первоначальным</span>
                            <span className="exclusive-special-span">взносом</span>
                        </p>
                        }
                        {bidPercent && <p>{bidPercent}%<br/>
                            <span
                                className="exclusive-special-span exclusive-special-span-top">Ставка</span>
                            {bidMortgage && <span className="exclusive-special-span">{bidMortgage}</span>}
                        </p>}
                    </>

                    {price && <p className="exclusive-item-grid-more-width">{price}₽<br/>
                        <span
                            className="exclusive-special-span exclusive-special-span-top">Стоимость</span>
                        <span className="exclusive-special-span">квартиры</span>
                    </p>}
                    {percent && <p><span>от</span> {percent}% <br/>
                        <span
                            className="exclusive-special-span exclusive-special-span-top">Ставка</span>
                        {bid && <span className="exclusive-special-span">{bid}</span>}
                    </p>}
                    {monthlyPayment && <p><span>от</span> {monthlyPayment}₽<br/>
                        <span
                            className="exclusive-special-span exclusive-special-span-top">{payment}</span>
                        <span className="exclusive-special-span">платёж </span>
                    </p>}
                    {floor && <p>
                        <span className="exclusive-special-span">Этаж №{floor}</span>
                        {apartment && <span className="exclusive-special-span">Квартира №{apartment}</span>}
                    </p>}
                </div>
            </div>
            <div className="exclusive-item-image"
                 style={{backgroundImage: `url(${imageUrl})`}}>
                <button className="exclusive-item-button" onClick={openModal}>Оставить
                    заявку
                </button>
            </div>
            <div className="exclusive-item-image-mobile">
                <div className="exclusive-item-image-mobile-new"
                     style={{backgroundImage: `url(${imageUrl})`}}></div>
                <button className="exclusive-item-button" onClick={openModal}>Оставить
                    заявку
                </button>
            </div>
        </div>
        {isOpen && <>
            <div className="overlay active" onClick={closeModal}></div>
            <Form modal={true}/>
        </>}
    </>
}

export default ExclusiveItem