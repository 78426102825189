import FirstBlock from './components/FirstBlock'
import Exclusive from './components/Exclusive'
import Comfort from './components/Comfort'
import Plan from './components/Plan'
import BuyingMethod from './components/BuyingMethod'
import Process from './components/Process'
import About from './components/About'
import ApartmentSelection from './components/ApartmentSelection'
import Reviews from './components/Reviews'
import Partners from './components/Partners'

const MainPage = () => {
  return (
    <>
      <FirstBlock />
      <Exclusive />
      <Comfort />
      <Plan />
      <BuyingMethod />
      <Process />
      <About />
      <ApartmentSelection />
      <Reviews />
      <Partners />
    </>
  )
}

export default MainPage
