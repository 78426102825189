import React from "react";

const PlusIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="53" height="52" viewBox="0 0 53 52"
                fill="none">
        <path
            d="M0.317073 26C0.317073 11.8157 11.8157 0.317073 26 0.317073H26.6341C40.8184 0.317073 52.3171 11.8157 52.3171 26C52.3171 40.1843 40.8184 51.6829 26.6341 51.6829H26C11.8157 51.6829 0.317073 40.1843 0.317073 26Z"
            fill="white" stroke="#CBCBCB" strokeWidth="0.634146"/>
        <path
            d="M36.7793 26.6341L37.4134 26.6341L37.4134 25.3659L36.7793 25.3659L36.7793 26.6341ZM36.7793 25.3659L16.4866 25.3659L16.4866 26.6341L36.7793 26.6341L36.7793 25.3659Z"
            fill="#898989"/>
        <path
            d="M27.267 16L27.267 15.3659L25.9987 15.3659L25.9987 16L27.267 16ZM25.9987 16L25.9987 36.2927L27.267 36.2927L27.267 16L25.9987 16Z"
            fill="#898989"/>
    </svg>
}

export default PlusIcon