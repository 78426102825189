const CompareButtonIcon = () => {
    return <svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.1906 0.0341711C22.321 0.263644 21.7775 0.946026 21.7232 1.88808L21.699 2.38326L21.1435 2.40741C20.7389 2.42553 20.5275 2.4678 20.3403 2.56442C20.0082 2.73954 19.7304 3.08375 19.6338 3.45212C19.5855 3.61517 19.3801 5.10071 19.1688 6.74929C18.8185 9.53921 18.7944 9.76264 18.8729 10.0404C18.9816 10.4209 19.4526 10.91 19.8149 11.0187C20.1712 11.1214 27.1158 11.1214 27.4721 11.0187C27.8344 10.91 28.3055 10.4209 28.4142 10.0404C28.4927 9.76264 28.4685 9.53921 28.1183 6.74929C27.9069 5.10071 27.7016 3.61517 27.6533 3.45212C27.5566 3.08375 27.2789 2.73954 26.9467 2.56442C26.7595 2.4678 26.5482 2.42553 26.1436 2.40741L25.594 2.38326L25.5639 1.88204C25.5095 1.0668 25.1291 0.48104 24.4346 0.154946C24.1447 0.0220928 23.4865 -0.0443335 23.1906 0.0341711ZM24.1749 1.10907C24.4829 1.33855 24.5735 1.52575 24.5976 1.97262L24.6218 2.39533H23.6496H22.6773V2.06924C22.6773 1.54387 22.8585 1.20569 23.251 1.03057C23.4926 0.915833 23.9696 0.958104 24.1749 1.10907ZM25.425 8.34353C25.5216 8.43411 25.5759 8.56093 25.5759 8.67566C25.5759 8.7904 25.5216 8.91721 25.425 9.0078L25.28 9.15876H23.6435H22.007L21.8621 9.0078C21.7655 8.91721 21.7111 8.7904 21.7111 8.67566C21.7111 8.56093 21.7655 8.43411 21.8621 8.34353L22.007 8.19256H23.6435H25.28L25.425 8.34353Z"
            fill="#E0BE74"/>
        <path
            d="M4.35467 0.0341711C3.48509 0.263644 2.9416 0.946026 2.88725 1.88808L2.8631 2.38326L2.30753 2.40741C1.90293 2.42553 1.69157 2.4678 1.50437 2.56442C1.17224 2.73954 0.894455 3.08375 0.797835 3.45212C0.749524 3.61517 0.544206 5.10071 0.332849 6.74929C-0.0174006 9.53921 -0.0415557 9.76264 0.0369484 10.0404C0.145646 10.4209 0.616671 10.91 0.978998 11.0187C1.33529 11.1214 8.27988 11.1214 8.63617 11.0187C8.9985 10.91 9.46952 10.4209 9.57822 10.0404C9.65672 9.76264 9.63257 9.53921 9.28232 6.74929C9.07096 5.10071 8.86564 3.61517 8.81733 3.45212C8.72071 3.08375 8.44293 2.73954 8.1108 2.56442C7.92359 2.4678 7.71224 2.42553 7.30764 2.40741L6.75811 2.38326L6.72791 1.88204C6.67357 1.0668 6.29312 0.48104 5.59866 0.154946C5.3088 0.0220928 4.65058 -0.0443335 4.35467 0.0341711ZM5.339 1.10907C5.64697 1.33855 5.73756 1.52575 5.76171 1.97262L5.78587 2.39533H4.81362H3.84138V2.06924C3.84138 1.54387 4.02254 1.20569 4.41506 1.03057C4.65661 0.915833 5.13368 0.958104 5.339 1.10907ZM6.58902 8.34353C6.68564 8.43411 6.73999 8.56093 6.73999 8.67566C6.73999 8.7904 6.68564 8.91721 6.58902 9.0078L6.44409 9.15876H4.80758H3.17107L3.02614 9.0078C2.92952 8.91721 2.87517 8.7904 2.87517 8.67566C2.87517 8.56093 2.92952 8.43411 3.02614 8.34353L3.17107 8.19256H4.80758H6.44409L6.58902 8.34353Z"
            fill="#E0BE74"/>
        <path d="M3.17188 13.791H26.157" stroke="#273E42" strokeWidth="2" strokeLinecap="round"/>
        <rect x="11.4453" y="13.791" width="6.43584" height="3.67762" rx="1.83881" fill="#273E42"/>
    </svg>
}

export default CompareButtonIcon;

