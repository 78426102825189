import React from 'react'
import './button.scss'

const Button = ({ name, children, type = 'button', onClick }: any) => {
  return (
    <button type={type} className={`button ${name}`} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
