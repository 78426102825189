import './style.css'
import ExclusiveItem from "../../../../entity/main-page/components/exclusive-item";
import Slider from "react-slick";
import {useEffect, useRef, useState} from "react";

const exclusiveArr = [
    {
        name: 'Апартаменты <br/>Тайм Парк',
        price: '4 900 000',
        percent: 3,
        bid: 'на весь срок',
        payment: 'Ежемесячный',
        monthlyPayment: '16 527',
        floor: 4,
        apartment: 9,
        imageUrl: '/img/exclusive1.png'
    },
    {
        name: 'Семейная <br/>ипотека',
        mortgage: '0',
        bidPercent: 3.5,
        bidMortgage: 'на весь срок',
        imageUrl: '/img/exclusive2.png'
    },
    {
        name: 'Старт продаж <br/>второй башни Willart',
        imgMobileUrl: '/img/exclusive1-mobile.png',
        date: 'август 2024',
        imageUrl: '/img/exclusive3.png'
    }
]
const Exclusive = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1180);
    const sliderRef = useRef<Slider | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const exclusiveSectionRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1180);
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const nextArrowHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevArrowHandler = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <button className="exclusive__slider__button exclusive__slider-left">&#8592;</button>,
        nextArrow: <button className="exclusive__slider__button exclusive__slider-right">&#8594;</button>,
        dots: false,
        infinite: false,
    };

    const toggleOffers = () => {
        setIsExpanded((prev: any) => !prev);
    };

    if (exclusiveSectionRef.current && !isExpanded) {
        exclusiveSectionRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
    return (
        <section className="exclusive" id="exclusive" ref={exclusiveSectionRef}>
            <div className="container">
                <div className="exclusive-body">
                    <h2 className="exclusive-title title">
                        Эксклюзивные <span>предложения</span>
                    </h2>
                    <div className="exclusive-list">
                        {isMobile ? (
                            <Slider ref={sliderRef} {...settings}>
                                <div className="exclusive-list__wrapper">
                                    {exclusiveArr.map((item, ind) => (
                                        <ExclusiveItem key={ind} {...item} />
                                    ))}
                                </div>
                                <div className="exclusive-list__wrapper">
                                    {exclusiveArr.map((item, ind) => (
                                        <ExclusiveItem key={ind} {...item} />
                                    ))}
                                </div>
                                <div className="exclusive-list__wrapper">
                                    {exclusiveArr.map((item, ind) => (
                                        <ExclusiveItem key={ind} {...item} />
                                    ))}
                                </div>
                            </Slider>
                        ) : (
                            <>
                                <div className="exclusive-list__wrapper">
                                    {exclusiveArr.map((item, ind) => (
                                        <ExclusiveItem key={ind} {...item} />
                                    ))}
                                </div>
                                <div className={`exclusive-list__wrapper ${isExpanded ? 'active' : ''}`}>
                                    {exclusiveArr.map((item, ind) => (
                                        <ExclusiveItem key={ind} {...item} />
                                    ))}
                                </div>
                                <div className={`exclusive-list__wrapper ${isExpanded ? 'active' : ''}`}>
                                    {exclusiveArr.map((item, ind) => (
                                        <ExclusiveItem key={ind} {...item} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <button className="exclusive__button button"
                            // style={{padding: getButtonPadding()}}
                            onClick={toggleOffers}
                    >
                        {isExpanded
                        ? "Скрыть"
                        : "Посмотреть все предложения"}
                    </button>
                    <div className="exclusive__slider__buttons">
                        <button
                            className="exclusive__slider__button exclusive__slider-left"
                            onClick={prevArrowHandler}
                        >
                            &#8592;
                        </button>
                        <button
                            className="exclusive__slider__button exclusive__slider-right"
                            onClick={nextArrowHandler}
                        >
                            &#8594;
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Exclusive;