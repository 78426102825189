import { useState, useEffect, useRef } from "react";

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const headerRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        headerRef.current = document.querySelector('.header');
    }, []);

    const openModal = () => {
        setIsOpen(true);
        document.body.classList.add("no-scroll");
        if (headerRef.current) {
            headerRef.current.classList.add('header-hidden');
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        document.body.classList.remove("no-scroll");
        if (headerRef.current) {
            headerRef.current.classList.remove('header-hidden');
        }
    };

    return {
        isOpen,
        openModal,
        closeModal,
    };
};

export default useModal;
