import OvalDecor from "../../../../shared/assets/icons/oval-decor";
import './style.scss'
const InfoItem = ({title, desc}: any) => {
    return <div className="single-info">
        <OvalDecor color={'#E0BE74'}/>
        {title && <h5 className="single-info__title">{title}</h5>}
        {desc && <div className="single-info__desc">{desc}</div>}
    </div>
}

export default InfoItem;