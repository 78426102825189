import Card from "../../../../entity/singel-card/components/card-apartment";
import './style.scss';
import {forwardRef, useEffect, useRef, useState} from "react";
import Tabs from "../../../../entity/singel-card/components/tabs";
import Scheme from "../Scheme";
import Loader from "../../../../entity/main-page/components/loader";
import {useParams} from "react-router-dom";
import {getOffersCard} from "../../../../shared/api/endpoints";

const tabs = [
    {name: 'Список'},
    {name: 'Схема'},
];
const sortOption = [
    {value: 0, name: 'По цене, сначала дешевле'},
    {value: 1, name: 'По цене, сначала дороже'},
]

const floorsData = Array(17).fill(null).map((_, index) => ({
    entrance: Math.ceil(Math.random() * 3),
    floor: index + 1,
    apartments: Array(Math.ceil(Math.random() * 4) + 1)
        .fill(null)
        .map(() => {
            const square = parseFloat((Math.random() * (150 - 25.9) + 25.9).toFixed(1));
            return {
                square,
                totalPrice: Math.round(square * 100000),
            };
        })
}));

const Apartments = forwardRef(({setFilteredCards, cards, loadMore, loading, dataLayout}: any, ref: any) => {
    const [activeTab, setActiveTab] = useState(0);
    const endOfListRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<number>(0);

    const {id} = useParams();
    useEffect(() => {
        loadMore(1);
    }, []);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             if (entries[0].isIntersecting) {
    //                 loadMore();
    //             }
    //         },
    //         {threshold: 1.0}
    //     );
    //
    //     if (endOfListRef.current) {
    //         observer.observe(endOfListRef.current);
    //     }
    //
    //     return () => {
    //         if (endOfListRef.current) {
    //             observer.unobserve(endOfListRef.current);
    //         }
    //     };
    // }, [endOfListRef, loadMore]);

    const handleOptionClick = async (value: any) => {
        console.log('value', value)
        setSelected(value);
        setIsOpen(false);
        await sortHandler(value);
    };

    const sortHandler = async (value: any) => {
        try {
            const data = await getOffersCard(id, value);
            setFilteredCards(data.results.map((item: any) => item['layout_fields']).map((group: any, groupIndex: number) =>
                group?.reduce((acc: any, item: any, ind: number) => {
                    const parentId = data.results[groupIndex]?.id;
                    acc[item.source] = item.data?.value || null;
                    acc['id'] = parentId;
                    return acc;
                }, {})));
        } catch (error) {
            console.error("Ошибка при получении предложений:", error);
        }
    };

    return <div className="apartments" ref={ref}>
        <Tabs tabs={tabs} setActive={setActiveTab} activeTab={activeTab}/>
        <div className="apartments__info">
            <h4 className="apartments__search">Найдено {cards.length} предложений</h4>
            <div className="apartments__sort">
                <div
                    className="form-label-select-content"
                    id="districtSelect"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selected ? sortOption.find((option) => option.value == selected)?.name : 'По цене, сначала дешевле'}
                </div>
                {isOpen && (
                    <ul className="filters__list active">
                        {sortOption.map((option, index) => (
                            <li
                                key={index}
                                className={`${selected == option.value ? "selected" : ""}`}
                                onClick={() => handleOptionClick(option.value)}
                            >
                                <button className={'filters__mobile-top'}>{option.name}</button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
        {loading && <Loader/>}
        {activeTab === 0 ? <div className="single__items">
            {cards.map((item: any, ind: number, arr?: any) => {
                return <Card key={ind} {...item} dataArr={arr} dataLayout={dataLayout}/>
            })}
            <div ref={endOfListRef} style={{height: '1px'}}/>
        </div> : <Scheme dataScheme={floorsData}/>}
    </div>
})

export default Apartments;