import Button from "../../../../entity/main-page/components/button";
import './style.scss'
import {formatPrice} from "../../../../entity/singel-card/components/card-apartment";
import Skeleton from "../../../../entity/singel-card/components/skeleton";
import useModal from "../../../../shared/hooks/useModal";
import Form from "../../../../entity/main-page/components/form";
import React from "react";

const Banner = ({title, price, apartmentDeadline, apartmentYear, loading}: any) => {
    const {isOpen, openModal, closeModal} = useModal();

    return <section className={"card-banner"} style={{backgroundImage: `url("/img/single/banner.jpg")`}}>
        <div className="container">
            <div className="card-banner__info">
                <h1 className="card-banner__title">{loading ? <Skeleton height={"1.1em"}/> : title}</h1>
                <div className="card-banner__body">
                    <div className="card-banner__btns">
                        <Button title={"Подобрать квартиру"} onClick={openModal} color={"#273E42"}/>
                        <a href="#specialist" className="card-banner__phone">Перезвоните мне</a>
                    </div>
                    <div className="card-banner__more">
                            {!loading ? price && <div className="card-banner__item">
                                <h5 className="card-banner__subtitle">Стоимость</h5>
                                <p className="card-banner__text">от {formatPrice(price)} р</p></div>
                                : <Skeleton className={"card-banner__skeleton"}/>}

                            {!loading ? apartmentDeadline && <div className="card-banner__item">
                                <h5 className="card-banner__subtitle">Срок сдачи</h5>
                                <p className="card-banner__text">{apartmentDeadline && `${apartmentDeadline} кв.`} {apartmentYear}</p>
                            </div> : <Skeleton className={"card-banner__skeleton"}/>}
                    </div>
                </div>
            </div>
        </div>
        {isOpen && <>
            <div className="overlay active" onClick={closeModal}></div>
            <Form modal={true}/>
        </>}
    </section>
}

export default Banner