import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ProcessSlider = ({data}: any) => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
    };
    return <div className="process-image">
        <Slider {...settings}>
            {data.map((item: any, ind: number) => {
                return <div key={ind}>
                    <img src={item.img} alt={item.alt}/>
                </div>
            })}
        </Slider>
    </div>
}

export default ProcessSlider