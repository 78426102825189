import './style.css'
import {useEffect, useRef, useState} from 'react'
import {HashLink as Link} from 'react-router-hash-link';
import useWindowDimensions from "../../../../shared/hooks/useWindowDimensions";

const Header = () => {
    const [isNavActive, setIsNavActive] = useState(false);
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const {width} = useWindowDimensions();

    const toggleNav = () => setIsNavActive(!isNavActive);
    const closeNav = () => setIsNavActive(false);

    const handleClickOutside = (e: MouseEvent) => {
        if (overlayRef.current && !overlayRef.current.contains(e.target as Node) && !isNavActive) {
            closeNav()
        }
    }
    const scrollToSection = (id: any) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    useEffect(() => {
        if (isNavActive) {
            document.addEventListener('click', handleClickOutside)
        } else {
            document.removeEventListener('click', handleClickOutside)
        }
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isNavActive])
    return (
        <>
            <header className='header'>
                <div className='container'>
                    <div className='header-body'>
                        <Link to={'/'} className='header-logo'>
                            <img src={width < 1080 ? '/img/svg/logo.svg' : '/img/logo.jpg'}
                                 className='header-logo-img'/>
                            <p className='header-logo-text'>агентство недвижимости</p>
                        </Link>
                        <nav className={`header-nav ${isNavActive ? 'active' : ''}`} ref={overlayRef}>
                            <div className='header-nav__top'>
                                <div className='header-nav__top-logo'>
                                    <img src='/img/logo-yellow.png' alt='логотип'/>
                                    <p className='header-nav__logo-text'>агентство недвижимости</p>
                                </div>
                                <div className='header-nav-close' onClick={closeNav}>
                                    &times;
                                </div>
                            </div>
                            <Link to='/#comfort'>ЖК</Link>
                            <Link to='/#partners'>Партнеры</Link>
                            <Link to='/#buying__method'>Способы покупки</Link>
                            <Link to='/#process'>Подбор квартир</Link>
                            <Link to='/#reviews'>Отзывы</Link>
                            <Link to='/#footer'>Контакты</Link>
                            <div className="header-nav__actions">
                                <button>
                                    <img src="/img/svg/favorites.svg" alt="Изранное"/>
                                    Изранное
                                </button>
                                <button>
                                    <img src="/img/svg/compare-menu.svg" alt="Сравнить"/>
                                    Сравнить
                                </button>
                            </div>
                            <Link className='header-button header-button-menu' to={'/#apartment-selection'}>
                                Подобрать квартиру
                            </Link>
                        </nav>
                        <div className='header-buttons'>
                            <div className='header-button-tel phone-menu'>
                                <a className='phone-icon' href='tel:+73832208888'>
                                    <img src='/img/svg/phoneHeader.svg' alt='Phone'/>
                                </a>
                                <a className='phone-icon-mobile' href='tel:+73832208888'>
                                    <img src='/img/svg/phoneHeaderMobile.svg' alt='Phone'/>
                                </a>
                                <div className='order-options'>
                                    <a className='order-options__icon' href='tel:+73832208888'>
                                        <img src='/img/svg/phoneHeader.svg' alt='Phone'/>
                                    </a>
                                    <a href='https://t.me/+73832208888' className='order-options__icon'>
                                        <img src='/img/svg/telegramHeader.svg' alt='Telegram'/>
                                    </a>
                                    <a href='https://wa.me/+73832208888/?text=urlencodedtext'
                                       className='order-options__icon'>
                                        <img src='/img/svg/watsapp.svg' alt='Watsapp'/>
                                    </a>
                                </div>
                            </div>
                            <Link className='header-button' to={'/#apartment-selection'}>
                                Подобрать квартиру
                            </Link>
                            <div className='header-burger' onClick={toggleNav}></div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`overlay ${isNavActive ? 'active' : ''}`} onClick={closeNav}></div>
        </>
    )
}

export default Header
