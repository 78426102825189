import {useEffect, useState} from "react";

type MortgageKey = keyof typeof mortgageData;

const mortgageData = {
    'Семейная': {
        rate: 6,
        downPayment: 20.1,
        maxLoan: 30000000,
        description: (
            <>
                <p className="plan-calc-info-title">Кто может оформить семейную ипотеку:</p>
                <p className="plan-calc-info-desc">
                    ребенок, который является гражданином РФ, и возраст которого
                    не достиг 7 лет на дату заключения кредитного договора; двое
                    и более детей, которые являются гражданами РФ, и возраст которых не достиг 18 лет на
                    дату заключения кредитного договора; ребенок, который является гражданином РФ, и
                    которому установлена категория "ребенок-инвалид", и возраст которого не достиг 18 лет
                    на дату заключения кредитного договора.
                </p>
            </>
        ),
    },
    'Рыночные условия': {
        rate: 20.5,
        downPayment: 10,
        maxLoan: 100000000,
        description: (
            <>
                <p className="plan-calc-info-title">Кто может оформить донорскую ипотеку:</p>
                <p className="plan-calc-info-desc">
                    Программа предлагается покупателям, которые не попадают под условия
                    «Семейной ипотеки», но у их знакомого или родственника есть ребенок
                    до 6 лет. Для участия в программе необходимо также выделить в квартире
                    долю 10% (не менее 6 кв. м) на «донора» (при этом выделять долю на ребенка не нужно).
                </p>
            </>
        ),
    },
    'IT-ипотека': {
        rate: 6,
        downPayment: 20.1,
        maxLoan: 18000000,
        description: (
            <>
                <p className="plan-calc-info-title">Кто может оформить IT-ипотеку:</p>
                <p className="plan-calc-info-desc">
                    Главное условие — вы работаете в IT-компании, которая: получила аккредитацию
                    Минцифры РФ; получает налоговые льготы; не зарегистрирована в Москве или
                    Санкт-Петербурге.
                </p>
            </>
        ),
    },
    'Военная ипотека': {
        rate: 6,
        downPayment: 20,
        maxLoan: 4740000,
        description: (
            <>
                <p className="plan-calc-info-title">Кто может оформить военную ипотеку:</p>
                <p className="plan-calc-info-desc">
                    Это льготная программа для военнослужащих. Распространяется она и на тех, кто
                    служит по контракту. Благодаря программе военнослужащий может получить жилье
                    в собственность. Важно! Даже если у военного уже есть жилье в собственности,
                    то он все равно может воспользоваться этой программой.
                </p>
            </>
        ),
    },
};

const Plan = () => {
    const [activeTab, setActiveTab] = useState<MortgageKey>('Семейная');
    const [loanAmount, setLoanAmount] = useState('3 000 000');
    const [rate, setRate] = useState('8,7%');
    const [downPayment, setDownPayment] = useState<string>('600 000');
    const [term, setTerm] = useState(10); // срок в годах
    const [monthlyPayment, setMonthlyPayment] = useState<string>('53 000');

    const formatNumberWithSpaces = (value: number) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const calculateMonthlyPayment = () => {
        const loanAmountValue = parseFloat(loanAmount.replace(/\s/g, '')) || 0;
        const downPaymentValue = parseFloat(downPayment.replace(/\s/g, '')) || 0;
        const rateValue = parseFloat(rate) / 100 / 12; // ежемесячная процентная ставка
        const loanTermMonths = term * 12; // срок в месяцах
        const loanSum = loanAmountValue - downPaymentValue;

        if (loanSum > 0 && rateValue > 0 && loanTermMonths > 0) {
            const monthly = loanSum * (rateValue * Math.pow(1 + rateValue, loanTermMonths)) /
                (Math.pow(1 + rateValue, loanTermMonths) - 1);
            setMonthlyPayment(formatNumberWithSpaces(Math.round(monthly)));
        } else {
            setMonthlyPayment('Ошибка');
        }
    };

    useEffect(() => {
        const data = mortgageData[activeTab];
        setRate(`${data.rate}%`);
        setDownPayment(formatNumberWithSpaces(Math.round((data.downPayment / 100) * data.maxLoan)));
        setLoanAmount(formatNumberWithSpaces(data.maxLoan));
        calculateMonthlyPayment();
    }, [activeTab]);


    useEffect(() => {
        calculateMonthlyPayment();
    }, [loanAmount, downPayment, rate, term]);

    return <section className="plan">
        <div className="container">
            <div className="plan-body">
                <div className="plan-body-text">
                    <h1 className="plan-title title">
                        Ваш идеальный <span>ипотечный план</span>
                    </h1>
                    <div className="plan-calc-info">
                        <div className="plan-calc-info-tab tabs">
                            {Object.keys(mortgageData).map((title) => (
                                <p
                                    key={title}
                                    className={`tab ${activeTab === title ? "is-active" : ""}`}
                                    onClick={() => setActiveTab(title as MortgageKey)}
                                >
                                    {title}
                                </p>
                            ))}
                        </div>
                        <div className="plan-calc-info-text tab_element is-active">
                            {mortgageData[activeTab].description}
                            <div className="plan-calc-info-nums">
                                <p>
                                    <span>от {mortgageData[activeTab].rate}%</span>Ставка
                                </p>
                                <p>
                                    <span>от {mortgageData[activeTab].downPayment}%</span>Первый взнос
                                </p>
                                <p>
                                    <span>до {formatNumberWithSpaces(mortgageData[activeTab].maxLoan)} ₽</span>Сумма
                                    кредита
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-calc">
                    <p className="plan-calc-title">Ипотечный калькулятор</p>
                    <form>
                        <label>
                            <span>Стоимость объекта, руб</span>
                            <input
                                type="text"
                                value={loanAmount}
                                onChange={(e) => setLoanAmount(formatNumberWithSpaces(Number(e.target.value.replace(/\D/g, ''))))}
                            />
                        </label>
                        <label>
                            <span>Процентная ставка, %</span>
                            <input
                                type="text"
                                value={rate}
                                onChange={(e) => setRate(e.target.value)}
                            />
                        </label>
                        <label>
                            <span>Первоначальный взнос, руб</span>
                            <input
                                type="text"
                                value={downPayment}
                                onChange={(e) => setDownPayment(formatNumberWithSpaces(Number(e.target.value.replace(/\D/g, ''))))}
                            />
                        </label>
                        <div className="plan-calc__slider-container">
                            <label>Срок, лет</label>
                            <input
                                type="range"
                                min="0"
                                max="30"
                                step="1"
                                value={term}
                                className="plan-calc__slider"
                                id="yearsRange"
                                onChange={(e) => setTerm(parseInt(e.target.value, 10))}
                            />
                            <div className="plan-calc__slider-labels">
                                {[...Array(7)].map((_, i) => (
                                    <span key={i}>{i * 5}</span>
                                ))}
                            </div>
                        </div>
                        <label className="plan-calc__modile-off">
                            <span>Ежемесячный платеж составит:</span>
                            <input type="text" value={monthlyPayment} readOnly/>
                            <p>В зависимости от выбранного вами банка, процентная ставка может меняться.</p>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    </section>
}

export default Plan