import Button from "../../../../entity/main-page/components/button";
import './style.scss'
import InputMask from "react-input-mask";
import {useState} from "react";

const Specialist = ({name, job, img}: { name: string, job: string, img: string }) => {
    const [phone, setPhone] = useState("");

    const handleChange = (e: any) => {
        setPhone(e.target.value);
    };
    return <div className="specialist" id={"specialist"}>
        <div className="specialist__wrapper">
            <div className="specialist__img">
                <img src={img} alt={name}/>
            </div>
            <h4 className="specialist__name">{name}</h4>
            <p className="specialist__job">{job}</p>
            <InputMask
                mask="+7 999 999 99 99"
                value={phone}
                onChange={handleChange}
                className="phone-input"
                placeholder="+7 900 000 00 00"
            >
                {(inputProps: any) => <input type="tel" name={'spec-phone'} {...inputProps}
                                             className="specialist__phone"/>}
            </InputMask>


        <Button title={"Перезвоните мне"} color={"#FFF"}/>
        <label className="form-label-flex form-label-politic">
                <input type="checkbox" name="" id="dataConsentFour"/>
                <span>Я даю согласие на обработку своих персональных данных</span>
            </label>
        </div>
    </div>

}

export default Specialist