import React from "react";

const MinusIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="53" height="52" viewBox="0 0 53 52"
                fill="none">
        <path
            d="M0.317073 26C0.317073 11.8157 11.8157 0.317073 26 0.317073H26.6341C40.8184 0.317073 52.3171 11.8157 52.3171 26C52.3171 40.1843 40.8184 51.6829 26.6341 51.6829H26C11.8157 51.6829 0.317073 40.1843 0.317073 26Z"
            fill="white" stroke="#CBCBCB" strokeWidth="0.634146"/>
        <path
            d="M17 26.3659H16.3659V27.6341H17V26.3659ZM17 27.6341H37.2927V26.3659H17V27.6341Z"
            fill="#898989"/>
    </svg>
}

export default MinusIcon;