import './App.css'
import Header from './pages/main/components/Header'
import Footer from './pages/main/components/Footer'
import {Route, Routes, useLocation, useParams} from 'react-router-dom'
import MainPage from './pages/main'
import SingleCard from './pages/single'
import {useEffect} from 'react'
import {getToken} from './shared/api/endpoints'
import CurrentApartment from './pages/current-appartament'

function App() {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getToken({
                    email_canonical: 'mirror@novactiv.ru',
                    password: 'NDpC8E',
                })
                const {access, refresh} = res
                localStorage.setItem('access', access)
                localStorage.setItem('refresh', refresh)
            } catch (error) {
            }
        }
        fetchData()
    })

    return (
        <>
            <Header/>
            <Routes>
                <Route path={'/'} element={<MainPage/>}/>
                <Route path={`/apartment/:id`} element={<SingleCard/>}/>
                <Route path={'/object/:id'} element={<CurrentApartment/>}/>
            </Routes>
            <Footer/>
        </>
    )
}

export default App
