const FavoriteButtonIcon = () => {
    return <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9944 0C14.9883 0 17 2.79375 17 5.4C17 10.6781 8.65111 15 8.5 15C8.34889 15 0 10.6781 0 5.4C0 2.79375 2.01167 0 5.00556 0C6.72444 0 7.84833 0.853125 8.5 1.60313C9.15167 0.853125 10.2756 0 11.9944 0Z"
            fill="#E0BE74"/>
    </svg>
}

export default FavoriteButtonIcon

